import { StorageFile } from "@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types";
import { createTheme } from "@mui/material";
import { Report } from "API";
import { uploadData } from "aws-amplify/storage";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { ReportKindNameEnumForRequest } from "common/enums/ReportKindEnum";
import { parsePlaceResult } from "common/functions/placesUtilities";
import { RequestEstate } from "common/interfaces/RequestEstate";
import { MainContextContainer } from "components/Main";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { useMapHooks } from "hooks/mapHooks";
import { useReportHooks } from "hooks/reportHooks";
import { useReportKindHooks } from "hooks/reportKindHooks";
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { v4 } from "uuid";
import { RequestInputMode } from "./enums";
import { RequestInputDialogProps } from "./interfaces";

export const useRequestInputDialogHooks = (props: RequestInputDialogProps) => {
  const mainContext = useContext(MainContextContainer);
  const contentRef = useRef<HTMLElement>(null);
  const filesRef = useRef<StorageFile[]>([]);

  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          scrollPaper: {
            className: "twbasic01"
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            fontFamily: "inherit",
            fontWeight: "700 !important",
            color: "black",

            "&.Mui-focused": {
              color: "black !important"
            },

            "&::before": {
              backgroundColor: "rgba(210, 200, 175, 1)",
              borderRadius: "1000px",
              content: '""',
              display: "inline-block",
              height: "1.5em",
              marginRight: "6px",
              width: "7px"
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&::before": {
              display: "none"
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            fontFamily: "inherit"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: "5px 5px !important"
          },
          input: {
            padding: "5px 5px !important",
            fontWeight: "600 !important"
          },
          notchedOutline: {
            border: "none"
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: "0px !important"
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: "inherit",
            fontWeight: "600 !important"
          }
        }
      }
    }
  });

  const [specifyMessage, setSpecifyMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [thanks, setThanks] = useState(false);

  const [estate, setEstate] = useState<Partial<RequestEstate>>({});

  const {
    report,
    forceReport,
    setReport,
    busy: busyForReport,
    createReport
  } = useReportHooks(
    null,
    mainContext.user,
    mainContext.company,
    estate,
    mainContext.setFatal
  );

  const { reportKinds } = useReportKindHooks(mainContext.setFatal);

  const {
    map,
    setMap,
    location,
    setLocation,
    center,
    setCenter,
    zoom,
    setZoom,
    places,
    placesService,
    setPlacesService,

    initialize: initializeMapStates
  } = useMapHooks();

  const humanFileSize = useCallback((size: number) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const readable = Number((size / Math.pow(1024, i)).toFixed(2)) * 1;

    return `${readable} ${["B", "kB", "MB", "GB", "TB"][i]}`;
  }, []);

  const onEstateChange = useCallback((value: Partial<RequestEstate>) => {
    setEstate((estate) => ({ ...estate, ...value }));
  }, []);

  const onPlacesChange: (
    places: google.maps.places.PlaceResult,
    latitude?: number | null,
    longitude?: number | null
  ) => Promise<void> = useCallback(
    async (places, latitude, longitude) => {
      const { location } = await parsePlaceResult(places, true);

      if (latitude && longitude) {
        setEstate((estate) => ({ ...estate, latitude }));
        setEstate((estate) => ({ ...estate, longitude }));
      } else if (location) {
        setEstate((estate) => ({ ...estate, latitude: location.lat() }));
        setEstate((estate) => ({ ...estate, longitude: location.lng() }));
      }
    },
    [setEstate]
  );

  const onToPlaceClick = useCallback(
    (
      address: string | null | undefined,
      latitude?: number | null,
      longitude?: number | null
    ) => {
      setBusy(true);

      const usingPlacesService =
        placesService ?? (map && new google.maps.places.PlacesService(map));

      if (usingPlacesService) {
        if (!placesService) {
          setPlacesService(usingPlacesService);
        }

        usingPlacesService.textSearch(
          {
            query: address || undefined,
            type: "address"
          },
          (results) => {
            if (results?.at(0)) {
              if (results[0].place_id) {
                usingPlacesService?.getDetails(
                  {
                    placeId: results[0].place_id,
                    fields: ["geometry", "address_components", "website"]
                  },
                  (results2) => {
                    if (results2) {
                      onPlacesChange(results2, latitude, longitude).finally(
                        () => setBusy(false)
                      );
                    } else {
                      setSpecifyMessage("該当箇所を検索できませんでした。");
                      setBusy(false);
                    }
                  }
                );
              } else {
                onPlacesChange(results[0], latitude, longitude).finally(() =>
                  setBusy(false)
                );
              }
            } else {
              setSpecifyMessage("該当住所を検索できませんでした。");
              setBusy(false);
            }
          }
        );
      }
    },
    [map, onPlacesChange, placesService, setPlacesService]
  );

  const onAddressToMapButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      onToPlaceClick(estate.name);
    },
    [onToPlaceClick, estate.name]
  );

  const onMapChange = useCallback(
    (value: ChangeEventValue) => {
      setCenter(value.center);
      setZoom(value.zoom);
    },
    [setCenter, setZoom]
  );

  const onMapReady = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [setMap]
  );

  const onLocationChange = useCallback(
    (value: google.maps.LatLng) => {
      onToPlaceClick(`${value.lat()},${value.lng()}`, value.lat(), value.lng());
    },
    [onToPlaceClick]
  );

  const onClose = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.TouchEvent<HTMLButtonElement>
    ) => {
      if (props.onClose) {
        props.onClose(event);
      }
    },
    [props]
  );

  const onOkButtonClick = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
    ) => {
      let error = false;

      if (props.mode === RequestInputMode.Create) {
        if (estate.estateType === undefined || estate.estateType === "") {
          setErrorMessage("物件種別を指定してください。");
          error = true;
        }

        if (
          estate.latitude === null ||
          estate.latitude === undefined ||
          estate.longitude === null ||
          estate.longitude === undefined
        ) {
          setErrorMessage("位置を指定してください。");
          error = true;
        }
      }

      if (error) {
        if (contentRef.current) {
          contentRef.current.scrollTop = 0;
        }
      } else {
        const newId = v4().toString();
        const newFileFolderName = `report_file/${newId}`;

        const fileKeys = filesRef.current.map(
          (file) => `${window.location.href}${newFileFolderName}/${file.key}`
        );

        const attachments = `
【添付資料】:
　${fileKeys.join("\n　")}
`;

        const newReport: Partial<Report> = {
          id: newId,
          longitude: estate.longitude,
          latitude: estate.latitude
        };

        newReport.reportKind = ReportKindNameEnumForRequest.登録依頼;

        newReport.estateId = "R-";
        newReport.estateName = estate.name || "-";

        switch (estate.estateType) {
          case EstateTypeNameEnum.土地:
          case EstateTypeNameEnum.分譲地:
            newReport.description = `
【種別】: ${estate.estateType ?? ""}
【取引態様】: ${estate.mediationKind ?? ""}
【価格】: ${estate.estatePrice ?? ""}
【現況】: ${estate.currentStatus ?? ""}
【敷地面積】: ${estate.squareOrTsuboArea ?? ""}
【地目】: ${estate.groundType ?? ""}
【建築条件】: ${estate.bldConditionType ?? ""}
【備考】: ${estate.remarks ?? ""}
${attachments}
`;
            break;

          case EstateTypeNameEnum.新築建売:
          case EstateTypeNameEnum.中古戸建:
            newReport.description = `
【種別】: ${estate.estateType ?? ""}
【取引態様】: ${estate.mediationKind ?? ""}
【価格】: ${estate.estatePrice ?? ""}
【現況】: ${estate.currentStatus ?? ""}
【築年月】${
              estate.estateType === EstateTypeNameEnum.新築建売 ? "(予定)" : ""
            }: ${estate.completionMonth ?? ""}
【敷地面積】: ${estate.squareOrTsuboArea ?? ""}
【延床面積】: ${estate.totalArea ?? ""}
【間取り】: ${estate.layout ?? ""}
【構造】: ${estate.structure ?? ""}
【建物階数】: ${estate.buildingFloors ?? ""}
【備考】: ${estate.remarks ?? ""}
${attachments}
`;
            break;

          case EstateTypeNameEnum.マンション:
            newReport.description = `
【種別】: ${estate.estateType ?? ""}
【取引態様】: ${estate.mediationKind ?? ""}
【価格】: ${estate.estatePrice ?? ""}
【現況】: ${estate.currentStatus ?? ""}
【マンション名称】: ${estate.mansionName ?? ""}
【専有面積】: ${estate.squareOrTsuboArea ?? ""}
【間取り】: ${estate.layout ?? ""}
【所在階】: ${estate.floor ?? ""}
【号室】: ${estate.room ?? ""}
【駐車場】: ${estate.parking ?? ""}
【管理費】: ${estate.managementFee ?? ""}
【修繕積立金】: ${estate.repairCost ?? ""}
【その他費用】: ${estate.otherCost ?? ""}
【備考】: ${estate.remarks ?? ""}
${attachments}
`;
            break;

          case EstateTypeNameEnum.事業用:
            newReport.description = `
【種別】: ${estate.estateType ?? ""}
【取引態様】: ${estate.mediationKind ?? ""}
【価格】: ${estate.estatePrice ?? ""}
【種目】: ${estate.purposeBs ?? ""}
【現況】: ${estate.currentStatus ?? ""}
【築年月】${estate.completionMonth ?? ""}
【敷地面積】: ${estate.squareOrTsuboArea ?? ""}
【延床面積】: ${estate.totalArea ?? ""}
【構造】: ${estate.structure ?? ""}
【建物階数】: ${estate.buildingFloors ?? ""}
【備考】: ${estate.remarks ?? ""}
${attachments}
`;
            break;
        }

        newReport.description = newReport.description
          ?.replace(/^\n+/g, "")
          ?.replace(/\s+$/g, "");

        newReport.companyId = mainContext.user?.companyId ?? undefined;
        newReport.companyName = [
          mainContext.company?.name ?? "",
          mainContext.company?.branchName ?? ""
        ].join(" ");
        newReport.owner = mainContext.userInfo?.username;
        newReport.userName = mainContext.user?.name ?? undefined;

        forceReport(newReport);

        setConfirm(true);
      }
    },
    [
      props.mode,
      estate.estateType,
      estate.latitude,
      estate.longitude,
      estate.name,
      estate.mediationKind,
      estate.estatePrice,
      estate.currentStatus,
      estate.squareOrTsuboArea,
      estate.groundType,
      estate.bldConditionType,
      estate.remarks,
      estate.completionMonth,
      estate.totalArea,
      estate.layout,
      estate.structure,
      estate.buildingFloors,
      estate.mansionName,
      estate.floor,
      estate.room,
      estate.parking,
      estate.managementFee,
      estate.repairCost,
      estate.otherCost,
      estate.purposeBs,
      mainContext.user?.companyId,
      mainContext.user?.name,
      mainContext.company?.name,
      mainContext.company?.branchName,
      mainContext.userInfo?.username,
      forceReport
    ]
  );

  const onConfirmClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      try {
        setConfirm(false);
        setBusy(true);

        for (const file of filesRef.current) {
          if (file.file) {
            const key = `reports/${report.id}/${file.key}`;

            await uploadData({
              path: key,
              data: file.file
            }).result;
          }
        }

        const value = await createReport();

        if (value) {
          props.onOkButtonClick?.(props.mode || RequestInputMode.Create, {
            ...value,
            __typename: "Report"
          });

          setBusy(false);
          setThanks(true);
        }
      } catch (e: any) {
        mainContext.setFatal(e);
      } finally {
        setBusy(false);
      }
    },
    [createReport, mainContext, props, report.id]
  );

  const onConfirmCloseClick = useCallback(() => {
    setConfirm(false);
  }, []);

  const onConfirmClose = onConfirmCloseClick;

  const onThanksCloseClick = useCallback(() => {
    setThanks(false);
    onClose({} as MouseEvent<HTMLButtonElement>);
  }, [onClose]);

  const onThanksClose = onThanksCloseClick;

  useEffect(() => {
    if (!props.open) {
      initializeMapStates();

      setSpecifyMessage("");
      setErrorMessage("");
      setBusy(false);
      setConfirm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    forceReport({});
  }, [forceReport]);

  useEffect(() => {
    if (mainContext.company?.latitude && mainContext.company?.longitude) {
      setEstate((estate) => ({
        ...estate,
        latitude: mainContext.company?.latitude ?? undefined,
        longitude: mainContext.company?.longitude ?? undefined
      }));
    }
  }, [mainContext.company?.latitude, mainContext.company?.longitude]);

  useEffect(() => {
    if (
      estate.latitude === null ||
      estate.latitude === undefined ||
      estate.longitude === null ||
      estate.longitude === undefined
    ) {
      setLocation(null);
    } else {
      setLocation({
        lat: estate.latitude,
        lng: estate.longitude
      });
    }
  }, [setLocation, estate.latitude, estate.longitude, setReport]);

  useEffect(() => {
    if (map) {
      const service = new google.maps.places.PlacesService(map);
      setPlacesService(service);
    }
  }, [map, setPlacesService]);

  useEffect(() => {
    setBusy(busyForReport);
  }, [busyForReport]);

  return {
    contentRef,
    filesRef,
    theme,

    estate,
    report,

    specifyMessage,

    reportKinds,

    map,
    location,
    center,
    zoom,
    places,

    errorMessage,

    busy,
    confirm,
    thanks,

    humanFileSize,

    onEstateChange,

    onAddressToMapButtonClick,

    onMapChange,
    onMapReady,

    onLocationChange,

    onOkButtonClick,
    onClose,
    onConfirmClick,
    onConfirmCloseClick,
    onConfirmClose,
    onThanksCloseClick,
    onThanksClose,

    setFatal: mainContext.setFatal
  } as const;
};

export type RequestInputDialogHooksType = ReturnType<
  typeof useRequestInputDialogHooks
>;
