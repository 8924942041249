import {
  CustomMVTLayer,
  ParsedMvtTile
} from "common/functions/classes/CustomMVTLayer";
import { TileLoadProps } from "common/types/TileLoadProps";

export class MojMVTPointLayer<
  ExtraProps extends {} = {}
> extends CustomMVTLayer<ExtraProps> {
  async getTileData(loadProps: TileLoadProps): Promise<ParsedMvtTile> {
    const data = await super.getTileData(loadProps);

    if (loadProps.index.z < 18) {
      (data as any).points = (data as any).lines;
    }

    return data;
  }
}
