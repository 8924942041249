import { Box } from "@mui/material";
import { PurposeSummaryB } from "common/interfaces/PurposeSummaryB";
import React, { useCallback } from "react";
import styles from "../EstateFilterDialog.module.scss";

export const PurposeSummaryBButton = React.memo(
  (props: {
    purposeSummaryBsMaster: PurposeSummaryB[];
    purposeSummaryBs: PurposeSummaryB[];
    onPurposeSummaryBsChange: (purposeSummaryBs: PurposeSummaryB[]) => void;
  }) => {
    const onPurposeSummaryBClick = useCallback(
      (purposeSummaryB: PurposeSummaryB) =>
        props.onPurposeSummaryBsChange(
          props.purposeSummaryBs.some(
            (element) => element.id === purposeSummaryB.id
          )
            ? props.purposeSummaryBs.filter(
                (element) => element.id !== purposeSummaryB.id
              )
            : [...props.purposeSummaryBs, purposeSummaryB]
        ),
      [props]
    );

    return (
      <>
        {props.purposeSummaryBsMaster.map((purposeSummaryB) => (
          <Box
            key={purposeSummaryB.id}
            onClick={() => onPurposeSummaryBClick(purposeSummaryB)}
            className={`${styles.btn_switch01} ${styles.click_act01} ${
              styles.pet
            } ${
              props.purposeSummaryBs.includes(purposeSummaryB)
                ? styles.on
                : styles.off
            }`}
          >
            {purposeSummaryB.name}
          </Box>
        ))}
      </>
    );
  }
);
