import { MVTLoader } from "@loaders.gl/mvt";
import type { BinaryFeatureCollection } from "@loaders.gl/schema";
import { getUrl } from "aws-amplify/storage";
import { TileLoadProps } from "common/types/TileLoadProps";
import { MVTLayer } from "deck.gl";
import type { Feature } from "geojson";

export type ParsedMvtTile = Feature[] | BinaryFeatureCollection;

export class CustomMVTLayer<
  ExtraProps extends {} = {}
> extends MVTLayer<ExtraProps> {
  private _delay?: number;
  private cachedData?: React.MutableRefObject<{
    [url: string]: {
      buf: ArrayBuffer;
    };
  }>;
  private _mockGML?: boolean;

  set delay(value: number) {
    this._delay = value;
  }

  set cachedTiles(value: React.MutableRefObject<{ [url: string]: any }>) {
    this.cachedData = value;
  }

  set mockGML(value: boolean) {
    this._mockGML = value;
  }

  async getTileData(loadProps: TileLoadProps): Promise<ParsedMvtTile> {
    const { data, binary } = this.state;
    const { index } = loadProps;

    const url = (data as string)
      .replace("{x}", index.x.toString())
      .replace("{y}", index.y.toString())
      .replace("{z}", index.z.toString());

    if (!url) {
      return Promise.reject("Invalid URL");
    }

    const getterInfo = {
      loadOptions: this.getLoadOptions(),
      buf: undefined as ArrayBuffer | undefined
    };

    getterInfo.loadOptions = {
      ...getterInfo.loadOptions,
      mimeType: "application/x-protobuf",
      mvt: {
        ...getterInfo.loadOptions?.mvt,
        coordinates: this.context.viewport.resolution ? "wgs84" : "local",
        tileIndex: index
        // Local worker debug
        // workerUrl: `modules/mvt/dist/mvt-loader.worker.js`
        // Set worker to null to skip web workers
        // workerUrl: null
      },
      gis: binary ? { format: "binary" } : {}
    };

    if (this._delay) {
      await new Promise((resolve) => setTimeout(resolve, this._delay));
    }

    if (
      this.cachedData &&
      this.cachedData.current[`${index.z}|${index.x}|${index.y}`]
    ) {
      getterInfo.buf =
        this.cachedData.current[`${index.z}|${index.x}|${index.y}`].buf;
    }

    if (!getterInfo.buf) {
      const presignedUrl = this._mockGML
        ? `http://localhost:20099/${url}`
        : await getUrl({
            path: `public/${url}`
          })
            .then((value) => value.url.href)
            .catch(() => undefined);

      if (presignedUrl) {
        const buf = await fetch(presignedUrl)
          .then((res) => res.arrayBuffer())
          .catch(() => undefined);

        if (buf) {
          getterInfo.buf = buf;

          if (this.cachedData) {
            this.cachedData.current[`${index.z}|${index.x}|${index.y}`] = {
              buf
            };

            for (const key of Object.keys(this.cachedData.current).filter(
              (x) => x.split("|").at(0) !== index.z.toString()
            )) {
              delete this.cachedData.current[key];
            }
          }
        }
      }
    }

    if (!getterInfo.buf) {
      return Promise.resolve([]);
    }

    const parsed = await MVTLoader.parse(
      getterInfo.buf,
      getterInfo.loadOptions
    ).catch(() => undefined);

    if (!parsed) {
      return Promise.resolve([]);
    }

    return Promise.resolve(parsed as ParsedMvtTile);
  }
}
