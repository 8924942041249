import { Box } from "@mui/material";
import { GroupEnum } from "common/enums/GroupEnum";
import { MainContext } from "common/interfaces/MainContext";
import React from "react";
import { NavigateFunction } from "react-router-dom";

export const Links = React.memo(
  (props: {
    styles: Record<string, string>;
    navigate: NavigateFunction;
    mainContext: MainContext;
  }) => {
    const { styles, navigate, mainContext } = props;

    return (
      <Box className={styles.links}>
        <Box component="ul">
          <Box component="li">
            <Box component="a" onClick={() => navigate("about")}>
              注意事項
            </Box>
          </Box>
          <Box component="li">
            <Box component="a" onClick={() => navigate("license")}>
              ライセンス表記
            </Box>
          </Box>
        </Box>
        {mainContext.groups &&
          mainContext.groups?.includes(GroupEnum.Administrator) && (
            <Box component="ul">
              <Box component="li">
                <Box
                  component="a"
                  onClick={() =>
                    window.open(
                      window.location.origin.includes("localhost")
                        ? "http://localhost:3009/check_estates"
                        : window.location.origin.includes("dev")
                        ? "https://devctl.sumora.jp/check_estates"
                        : "https://ctl.sumora.jp/check_estates",
                      "_blank"
                    )
                  }
                >
                  ☆ 物件チェック
                </Box>
              </Box>
              <Box component="li">
                <Box component="a" onClick={() => navigate("condominium")}>
                  ☆ マンションマスタのマップ表示
                </Box>
              </Box>
              <Box component="li">
                <Box component="a" onClick={() => navigate("draft")}>
                  ☆ 下書き物件のマップ表示
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    );
  }
);
