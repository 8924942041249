import { Company, Estate, Report, User } from "API";
import { InfoNameEnum } from "common/enums/InfoNameEnum";
import {
  createReport as invokeCreateReport,
  deleteReport as invokeDeleteReport,
  getReport as invokeGetReport,
  updateReport as invokeUpdateReport
} from "common/functions/api/reports";
import { useAutoReducer } from "common/functions/reducers";
import { handleError } from "common/functions/utilities";
import { RequestEstate } from "common/interfaces/RequestEstate";
import { useCallback, useEffect, useState } from "react";

export const useReportHooks = (
  reportId: string | null | undefined,
  user: User | null | undefined,
  company: Company | null | undefined,
  estate: Estate | Partial<RequestEstate> | null | undefined,
  setFatal: (error?: string | null | undefined) => void
) => {
  const [report, initializeReport, forceReport, setReport] = useAutoReducer<
    Partial<Report>
  >({} as Partial<Report>);

  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [disable, setDisable] = useState(false);

  const loadReport = useCallback(() => {
    if (reportId) {
      setBusy(true);
      setDisable(false);
      setError(false);

      invokeGetReport(reportId, InfoNameEnum.master)
        .then((value) => {
          if (value) {
            forceReport({
              ...value,
              __typename: "Report"
            });
          }
        })
        .catch((reason) => {
          setDisable(true);
          setError(true);
          handleError(reason, setFatal);
        })
        .finally(() => setBusy(false));
    }
  }, [forceReport, setFatal, reportId]);

  const constructReportFromInputValue = useCallback(() => {
    report.estateName = estate?.name ?? "";
    report.companyId = company?.id ?? "";
    report.companyName = `${company?.name ?? ""}${company?.branchName ?? ""}`;
    report.userName = user?.name ?? "";
    report.owner = user?.username ?? "";

    return report as Report;
  }, [
    report,
    estate?.name,
    company?.id,
    company?.name,
    company?.branchName,
    user?.name,
    user?.username
  ]);

  const createReport = useCallback(async () => {
    setBusy(true);
    setError(false);

    const report = constructReportFromInputValue();

    const value = await invokeCreateReport(report)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value) {
      forceReport(value);
    }

    return value;
  }, [constructReportFromInputValue, forceReport, setFatal]);

  const updateReport = useCallback(async () => {
    setBusy(true);
    setError(false);

    const report = constructReportFromInputValue();

    const value = await invokeUpdateReport(report)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value) {
      forceReport(value);
    }

    return value;
  }, [constructReportFromInputValue, forceReport, setFatal]);

  const deleteReport = useCallback(async () => {
    setBusy(true);
    setError(false);

    const report = constructReportFromInputValue();

    const value = await invokeDeleteReport(report)
      .catch((reason) => {
        setError(true);
        handleError(reason, setFatal);
      })
      .finally(() => setBusy(false));

    if (value) {
      forceReport(value);
    }

    return value;
  }, [constructReportFromInputValue, forceReport, setFatal]);

  useEffect(() => {
    loadReport();
  }, [loadReport, reportId]);

  return {
    report,
    initializeReport,
    forceReport,
    setReport,
    busy,
    error,
    disable,

    createReport,
    updateReport,
    deleteReport
  } as const;
};
