import { BldConditionSelection } from "common/interfaces/BldConditionSelection";
import { EstateType } from "common/interfaces/EstateType";
import { MainContext } from "common/interfaces/MainContext";
import { Prefecture } from "common/interfaces/Prefecture";
import { PurposeSummaryB } from "common/interfaces/PurposeSummaryB";
import {
  useCityHooks,
  usePrefectureAndCityFromCityCodesHooks
} from "hooks/cityHooks";
import { useJsonMasterHooks } from "hooks/jsonMasterHooks";
import { usePrefectureHooks } from "hooks/prefectureHooks";
import { useStatusHooks } from "hooks/statusHooks";

export const useSearchMasterStates = (
  mainContext: MainContext,
  searchPrefectures: Prefecture[]
) => {
  const { statuses } = useStatusHooks(
    false,
    false,
    false,
    false,
    mainContext.setFatal
  );
  const { jsonMasters: estateTypes } = useJsonMasterHooks<EstateType>(
    "estate_types",
    mainContext.setFatal
  );
  const { prefectures } = usePrefectureHooks(mainContext.setFatal);
  const { cities } = useCityHooks(searchPrefectures, mainContext.setFatal);
  const { jsonMasters: bldConditionSelections } =
    useJsonMasterHooks<BldConditionSelection>(
      "bld_condition_selections",
      mainContext.setFatal
    );
  const { jsonMasters: purposeSummaryBs } = useJsonMasterHooks<PurposeSummaryB>(
    "purpose_summary_bs",
    mainContext.setFatal
  );
  const {
    selectedPrefectures: defaultPrefectures,
    selectedCities: defaultCities
  } = usePrefectureAndCityFromCityCodesHooks(
    [
      ...(mainContext.company?.areas ?? []),
      ...(mainContext.company?.areaPrefs ?? [])
    ],
    mainContext.setFatal
  );

  return {
    statuses,
    estateTypes,
    prefectures,
    cities,
    defaultPrefectures,
    defaultCities,
    bldConditionSelections,
    purposeSummaryBs
  } as const;
};

export type SearchMasterStatesType = ReturnType<typeof useSearchMasterStates>;
