import { Box } from "@mui/material";
import { Announce } from "API";
import { MainContext } from "common/interfaces/MainContext";
import { LevelChip } from "components/Parts/Atoms/LevelChip";
import { useAnnounceHooks } from "hooks/announceHooks";
import React from "react";
import { AnnounceDialog } from "../../AnnounceDialog";

export const Info = React.memo(
  (props: { styles: Record<string, string>; mainContext: MainContext }) => {
    const { styles, mainContext } = props;
    const setFatal = props.mainContext.setFatal;
    const [announces, setAnnounces] = React.useState<Announce[] | undefined>(
      undefined
    );
    const [selectedAnnounceId, setSelectedAnnounceId] = React.useState<
      string | null | undefined
    >(null);
    const { listAnnounces } = useAnnounceHooks(setFatal);

    React.useEffect(() => {
      listAnnounces().then((res) => {
        setAnnounces(res);
      });
    }, [listAnnounces]);

    return (
      <>
        <Box className={styles.info}>
          <Box className={styles.title}>お知らせ</Box>
          <Box className={styles.content}>
            <Box component="ul">
              {announces === undefined ? null : announces.length === 0 ? (
                <Box component="li">　お知らせはありません。</Box>
              ) : (
                announces.map((announce) => (
                  <Box component="li" key={announce.id}>
                    <Box
                      component="a"
                      onClick={() => {
                        setSelectedAnnounceId(announce.id);
                      }}
                    >
                      {announce.createdAt
                        ? new Date(announce.createdAt)
                            .toLocaleDateString("ja-JP")
                            .split("/")
                            .map((v) => v.padStart(2, "0"))
                            .join("/")
                        : "----/--/--"}{" "}
                      <LevelChip level={announce.level} />
                      <Box component="br" className={styles.br} />
                      <Box component="span" className={styles.subject}>
                        {announce.subject}
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </Box>
        <AnnounceDialog
          open={selectedAnnounceId !== null && selectedAnnounceId !== undefined}
          mainContext={mainContext}
          announceId={selectedAnnounceId ?? ""}
          onOkButtonClick={() => {
            setSelectedAnnounceId(null);
          }}
        />
      </>
    );
  }
);
