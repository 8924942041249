import { Box } from "@mui/material";
import { boundSearchableZoom } from "common/consts/estates";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { GroupEnum } from "common/enums/GroupEnum";
import { GpsButton } from "components/Parts/Atoms/GpsButton";
import { Loading } from "components/Parts/Atoms/Loading";
import { EstateFilterDialog } from "components/Parts/Molecules/EstateFilterDialog";
import { SearchMenu } from "components/Parts/Molecules/SearchMenu";
import { SearchRouteDialog } from "components/Parts/Molecules/SearchRouteDialog";
import { UsageGuideDialog } from "components/Parts/Molecules/UsageGuideDialog";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import { EstateLocationInputDialog } from "components/Parts/Organisms/EstateLocationInputDialog";
import { HeaderBar } from "components/Parts/Organisms/HeaderBar";
import { SearchMap } from "components/Parts/Organisms/SearchMap";
import { StickyNoteInputDialog } from "components/Parts/Organisms/StickyNoteInputDialog";
import { StickyNoteInputMode } from "components/Parts/Organisms/StickyNoteInputDialog/enums";
import { SwitchAreaDialog } from "components/Parts/Organisms/SwitchAreaDialog";
import { isEmpty } from "lodash";
import React, { Suspense } from "react";
import { EstateLocationInputMode } from "../EstateLocationInputDialog/enums";
import { ReportInputDialog } from "../ReportInputDialog";
import { ReportInputMode } from "../ReportInputDialog/enums";
import styles from "./SearchPage.module.scss";
import { menuButtonId, searchMapDivId, searchMapId } from "./consts";
import { useSearchHooks } from "./hooks";
import { SearchProps } from "./interfaces";
import { useMenuHooks as useSearchMenuHooks } from "./menuHooks";

export const SearchPage = React.memo((props: SearchProps) => {
  const {
    mainContext,
    behaviorStates,
    mapStates,
    masterStates,
    estateStates,
    stickyNoteStates,
    estateSearchConditionStates,
    filterConditionStates,
    dialogStates,
    commonPageHandlers,
    estateLocationInputDialogHandlers,
    reportInputDialogHandlers,
    stickyNoteInputDialogHandlers,
    switchAreaDialogHandlers,
    estateFilterDialogHandlers,
    mapHandlers,
    modalHandlers,
    usageGuideDialogHandlers,
    searchRouteDialogHandlers,
    searchEstateMethods,
    defaultFilterConditions
  } = useSearchHooks(props);

  const {
    switchStatus,
    landStatus,
    blockStatus,
    newStatus,
    usedStatus,
    apartStatus,
    businessStatus,

    newEstateStatus,
    updatePriceStatus,

    filterStatus,

    onOpenStatusChange,

    onPlacesChange,
    onEstateChange,

    onSwitchClick,

    onEstateTypeStatusChange,

    onNewEstateStatusChange,
    onUpdatePriceStatusChange,
    onPriceStatusChange,
    onSizeStatusChange,
    onFilterClick,
    onHistoryStatusChange,
    onEyeStatusChange,

    onSyoStatusChange,
    onChuStatusChange,
    onMountainStatusChange,
    onFloodStatusChange,
    onWaveStatusChange,
    onHighTideStatusChange,
    onUsageStatusChange,
    onKozuStatusChange,

    onMeasureStatusChange,
    onSquareStatusChange,
    onNaviStatusChange,
    onShopStatusChange,
    onMemoStatusChange,
    onGpsClick
  } = useSearchMenuHooks(
    props.searchMode,
    mainContext,
    masterStates,
    estateSearchConditionStates,
    filterConditionStates,
    mapStates,
    behaviorStates,
    dialogStates,
    estateStates,
    defaultFilterConditions,
    searchEstateMethods
  );

  return (
    <>
      <style>
        {`
          body {
            overflow: hidden;
          }
        `}
      </style>
      <ContentMain>
        <HeaderBar
          authStatus={mainContext.authStatus}
          authUser={mainContext.authUser}
          user={mainContext.user}
          company={mainContext.company}
          userInfo={mainContext.userInfo}
          groups={mainContext.groups}
          menuButtonId={menuButtonId}
          filterStatus={filterStatus}
          landStatus={landStatus}
          blockStatus={blockStatus}
          newStatus={newStatus}
          usedStatus={usedStatus}
          apartStatus={apartStatus}
          businessStatus={businessStatus}
          onFilterClick={onFilterClick}
        />
        <SearchMenu
          open={behaviorStates.openMenu}
          map={mapStates.map}
          filteredPublicEstates={estateStates.filteredPublicEstates}
          filteredClosedEstates={estateStates.filteredClosedEstates}
          landStatus={landStatus}
          blockStatus={blockStatus}
          newStatus={newStatus}
          usedStatus={usedStatus}
          apartStatus={apartStatus}
          businessStatus={businessStatus}
          newEstateStatus={newEstateStatus}
          updatePriceStatus={updatePriceStatus}
          priceStatus={mapStates.dspPrice}
          sizeStatus={mapStates.dspTsuboArea}
          filterStatus={filterStatus}
          historyStatus={behaviorStates.soldMode}
          eyeStatus={behaviorStates.saleMode}
          switchStatus={switchStatus}
          shopStatus={mapStates.dspPoi}
          syoStatus={mapStates.dspESchools}
          chuStatus={mapStates.dspJSchools}
          mountainStatus={mapStates.dspDoshasaigai}
          floodStatus={mapStates.dspKouzui}
          waveStatus={mapStates.dspTsunami}
          highTideStatus={mapStates.dspTakashio}
          usageStatus={mapStates.dspYouto}
          kozuStatus={mapStates.dspMoj}
          measureStatus={mapStates.measureLengthMode}
          squareStatus={mapStates.measureAreaMode}
          naviStatus={mapStates.searchRouteMode}
          memoStatus={behaviorStates.stickyNoteMode}
          isFree={
            mainContext.groups?.every(
              (group) => group === GroupEnum.RealEstateAgencyFree
            ) ?? true
          }
          onOpenStatusChange={onOpenStatusChange}
          onPlacesChange={onPlacesChange}
          onEstateChange={onEstateChange}
          onLandStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.土地)
          }
          onBlockStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.分譲地)
          }
          onNewStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.新築建売)
          }
          onUsedStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.中古戸建)
          }
          onApartStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.マンション)
          }
          onBusinessStatusChange={(on) =>
            onEstateTypeStatusChange(on, EstateTypeNameEnum.事業用)
          }
          onNewEstateStatusChange={onNewEstateStatusChange}
          onUpdatePriceStatusChange={onUpdatePriceStatusChange}
          onPriceStatusChange={onPriceStatusChange}
          onSizeStatusChange={onSizeStatusChange}
          onFilterClick={onFilterClick}
          onHistoryStatusChange={onHistoryStatusChange}
          onEyeStatusChange={onEyeStatusChange}
          onSwitchClick={onSwitchClick}
          onShopStatusChange={onShopStatusChange}
          onSyoStatusChange={onSyoStatusChange}
          onChuStatusChange={onChuStatusChange}
          onMountainStatusChange={onMountainStatusChange}
          onFloodStatusChange={onFloodStatusChange}
          onWaveStatusChange={onWaveStatusChange}
          onHighTideStatusChange={onHighTideStatusChange}
          onUsageStatusChange={onUsageStatusChange}
          onKozuStatusChange={onKozuStatusChange}
          onMeasureStatusChange={onMeasureStatusChange}
          onSquareStatusChange={onSquareStatusChange}
          onNaviStatusChange={onNaviStatusChange}
          onMemoStatusChange={onMemoStatusChange}
        />
        <GpsButton onClick={onGpsClick} />
        <Suspense fallback={Loading}>
          {mainContext.user && mainContext.company && mainContext.isAgreed && (
            <>
              <Box
                id={searchMapId}
                onClick={commonPageHandlers.invokeMenuClose}
                className={styles.wrapper}
              >
                <SearchMap
                  className={styles.searchMap}
                  mapDivId={searchMapDivId}
                  myPosition={
                    mainContext.company?.latitude &&
                    mainContext.company?.longitude
                      ? {
                          lat: mainContext.company.latitude,
                          lng: mainContext.company.longitude
                        }
                      : null
                  }
                  center={mapStates.center}
                  zoom={mapStates.zoom}
                  citiesOfSearchConditions={
                    estateSearchConditionStates.estateSearchConditions.cities
                  }
                  estates={estateStates.filteredPublicEstates}
                  closedEstates={estateStates.filteredClosedEstates}
                  soldEstates={estateStates.soldEstates}
                  selectedEstateOnAutoComplete={
                    estateStates.selectedEstateOnAutoComplete
                  }
                  stickyNotes={stickyNoteStates.stickyNotes}
                  stickyNotesChangeDispatcher={
                    stickyNoteStates.stickyNotesChangeDispatcher
                  }
                  editMode={behaviorStates.editMode}
                  searchMode={props.searchMode}
                  stickyNoteMode={behaviorStates.stickyNoteMode}
                  boundMode={
                    estateSearchConditionStates.estateSearchConditions.bound
                  }
                  soldMode={behaviorStates.soldMode}
                  places={mapStates.places}
                  currentPosition={mapStates.currentPosition}
                  boundSearch={
                    estateSearchConditionStates.estateSearchConditions.bound
                  }
                  boundSearchableZoom={boundSearchableZoom}
                  dspPrice={mapStates.dspPrice}
                  dspTsuboArea={mapStates.dspTsuboArea}
                  dspESchools={mapStates.dspESchools}
                  dspJSchools={mapStates.dspJSchools}
                  schoolAreas={mapStates.schoolAreas}
                  schoolPoints={mapStates.schoolPoints}
                  drawingPrefs={
                    estateSearchConditionStates.estateSearchConditions.prefs
                  }
                  drawingCities={
                    estateSearchConditionStates.estateSearchConditions.cities
                  }
                  dspDoshasaigai={mapStates.dspDoshasaigai}
                  dspKouzui={mapStates.dspKouzui}
                  dspTeiichitai={mapStates.dspTeiichitai}
                  dspTsunami={mapStates.dspTsunami}
                  dspTakashio={mapStates.dspTakashio}
                  dspYouto={mapStates.dspYouto}
                  dspPoi={mapStates.dspPoi}
                  dspTile16={behaviorStates.soldMode}
                  dspMoj={mapStates.dspMoj}
                  searchRouteMode={mapStates.searchRouteMode}
                  routeLocations={mapStates.routeLocations}
                  deleteRouteLocation={mapStates.deleteRouteLocation}
                  optimizeWaypoints={mapStates.optimizeWaypoints}
                  travelMode={mapStates.travelMode}
                  routeLocationsResult={mapStates.routeLocationsResult}
                  searchTrigger={mapStates.searchTrigger}
                  measureLengthMode={mapStates.measureLengthMode}
                  measureAreaMode={mapStates.measureAreaMode}
                  company={mainContext.company}
                  groups={mainContext.groups}
                  username={mainContext.userInfo?.username}
                  busy={behaviorStates.busy}
                  busyForMove={mapStates.busyForMove}
                  creatingPublicEstate={behaviorStates.creatingPublicEstate}
                  setCreatingPublicEstate={
                    behaviorStates.setCreatingPublicEstate
                  }
                  news={false}
                  own={
                    filterConditionStates.filterConditions.companyRecNo !== 0
                  }
                  estatesCount={estateStates.estatesCount}
                  estatesTotalCount={estateStates.estatesTotalCount}
                  notifiedEstate={estateStates.notifiedEstate}
                  onModalCancelClick={modalHandlers.onModalCancelClick}
                  onMapReady={mapHandlers.onMapReady}
                  onSoldMarkerClick={mapHandlers.onSoldMarkerClick}
                  onStickyNoteMarkerClick={mapHandlers.onStickyNoteMarkerClick}
                  onBoundChange={mapHandlers.onBoundChange}
                  onRouteLocationsChange={mapHandlers.onRouteLocationsChange}
                  onTravelModeClear={mapHandlers.onTravelModeClear}
                  onToggleSearchTrigger={mapHandlers.onToggleSearchTrigger}
                  onRouteLocationsResultChange={
                    mapHandlers.onRouteLocationsResultChange
                  }
                  onUpdateEstate={mapHandlers.onUpdateEstate}
                  onDeleteEstate={mapHandlers.onDeleteEstate}
                  onReportEstate={mapHandlers.onReportEstate}
                  onDisplayEstate={mapHandlers.onDisplayEstate}
                  onCreateStickyNote={mapHandlers.onCreateStickyNote}
                  onUpdateStickyNote={mapHandlers.onUpdateStickyNote}
                  onDeleteStickyNote={mapHandlers.onDeleteStickyNote}
                  onQuitMeasureClick={mapHandlers.onQuitMeasureClick}
                />
                <Box className={styles.mapInfo}>{`zoom: ${
                  mapStates.zoom
                } / 取得物件数: ${
                  estateStates.filteredPublicEstatesCount +
                  estateStates.filteredClosedEstatesCount
                }`}</Box>
              </Box>
              <SwitchAreaDialog
                open={dialogStates.openSwitchAreaDialog}
                isAdministrator={
                  (mainContext.groups || []).includes(
                    GroupEnum.Administrator
                  ) || (mainContext.groups || []).includes(GroupEnum.Staff)
                }
                selectedPrefectures={
                  estateSearchConditionStates.estateSearchConditions.prefs
                }
                selectedCities={
                  estateSearchConditionStates.estateSearchConditions.cities
                }
                setFatal={mainContext.setFatal}
                onClose={switchAreaDialogHandlers.onSwitchAreaDialogClose}
                onOkButtonClick={
                  switchAreaDialogHandlers.onSwitchAreaDialogOkButtonClick
                }
              />
              <EstateFilterDialog
                open={dialogStates.showEstateFilterDialog}
                style={{ zIndex: dialogStates.estateFilterDialogZIndex }}
                filterConditions={filterConditionStates.filterConditions}
                companyRecNo={mainContext.company?.recNo}
                setFatal={mainContext.setFatal}
                onMouseDown={
                  estateFilterDialogHandlers.onEstateFilterDialogMouseDown
                }
                onClose={estateFilterDialogHandlers.onEstateFilterDialogClose}
                onOkButtonClick={estateFilterDialogHandlers.onOkButtonClick}
              />
              <EstateLocationInputDialog
                open={!isEmpty(dialogStates.estateLocationInputDialogMode)}
                okButtonCaption={
                  dialogStates.estateLocationInputDialogMode === "update"
                    ? "更新"
                    : dialogStates.estateLocationInputDialogMode === "delete"
                    ? "削除"
                    : "-"
                }
                busy={false}
                mode={
                  dialogStates.estateLocationInputDialogMode === "update"
                    ? EstateLocationInputMode.Update
                    : dialogStates.estateLocationInputDialogMode === "delete"
                    ? EstateLocationInputMode.Delete
                    : null
                }
                estateId={
                  dialogStates.estateLocationInputDialogEstateId || null
                }
                errorMessage={""}
                onClose={estateLocationInputDialogHandlers.onClose}
                onOkButtonClick={
                  estateLocationInputDialogHandlers.onOkButtonClick
                }
              />
              <ReportInputDialog
                open={!isEmpty(dialogStates.reportInputDialogMode)}
                okButtonCaption="報告"
                busy={false}
                mode={ReportInputMode.Create}
                estateId={dialogStates.reportInputDialogEstateId || null}
                errorMessage={""}
                onClose={reportInputDialogHandlers.onClose}
                onOkButtonClick={reportInputDialogHandlers.onOkButtonClick}
              />
              <StickyNoteInputDialog
                open={!isEmpty(dialogStates.stickyNoteInputDialogMode)}
                okButtonCaption={
                  dialogStates.stickyNoteInputDialogMode === "create"
                    ? "作成"
                    : dialogStates.stickyNoteInputDialogMode === "update"
                    ? "更新"
                    : dialogStates.stickyNoteInputDialogMode === "delete"
                    ? "削除"
                    : "-"
                }
                busy={false}
                mode={
                  dialogStates.stickyNoteInputDialogMode === "create"
                    ? StickyNoteInputMode.Create
                    : dialogStates.stickyNoteInputDialogMode === "update"
                    ? StickyNoteInputMode.Update
                    : dialogStates.stickyNoteInputDialogMode === "delete"
                    ? StickyNoteInputMode.Delete
                    : null
                }
                stickyNoteId={
                  dialogStates.stickyNoteInputDialogStickyNote?.id || null
                }
                initialLatitude={
                  dialogStates.stickyNoteInputDialogInitialLatitude || null
                }
                initialLongitude={
                  dialogStates.stickyNoteInputDialogInitialLongitude || null
                }
                errorMessage={""}
                onClose={
                  stickyNoteInputDialogHandlers.onStickyNoteInputDialogClose
                }
                onOkButtonClick={
                  stickyNoteInputDialogHandlers.onStickyNoteInputDialogOkButtonClick
                }
              />
              <SearchRouteDialog
                style={{ zIndex: dialogStates.searchRouteDialogZIndex }}
                show={mapStates.searchRouteMode}
                map={mapStates.map}
                routeLocations={mapStates.routeLocations}
                travelMode={mapStates.travelMode}
                routeLocationsResult={mapStates.routeLocationsResult}
                optimizeWaypoints={mapStates.optimizeWaypoints}
                onClose={searchRouteDialogHandlers.onSearchRouteDialogClose}
                onMouseDown={
                  searchRouteDialogHandlers.onSearchRouteDialogMouseDown
                }
                onSearchButtonClick={
                  searchRouteDialogHandlers.onSearchRouteButtonClick
                }
                onRouteLocationDelete={
                  searchRouteDialogHandlers.onRouteLocationDelete
                }
                onOptimizeWaypointsChange={
                  searchRouteDialogHandlers.onOptimizeWaypointsChange
                }
              />
              <UsageGuideDialog
                show={
                  mapStates.dspDoshasaigai ||
                  mapStates.dspKouzui ||
                  mapStates.dspTeiichitai ||
                  mapStates.dspTsunami ||
                  mapStates.dspTakashio ||
                  mapStates.dspYouto
                }
                dspDoshasaigai={mapStates.dspDoshasaigai}
                dspKouzui={mapStates.dspKouzui}
                dspTeiichitai={mapStates.dspTeiichitai}
                dspTsunami={mapStates.dspTsunami}
                dspTakashio={mapStates.dspTakashio}
                dspYouto={mapStates.dspYouto}
                onClose={() => {}}
                onMouseDown={() => {}}
                onOffClick={usageGuideDialogHandlers.onUsageGuideDialogOffClick}
              />
            </>
          )}
        </Suspense>
      </ContentMain>
    </>
  );
});
