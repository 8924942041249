import { StorageManager } from "@aws-amplify/ui-react-storage";
import { StorageFile } from "@aws-amplify/ui-react-storage/dist/types/components/StorageManager/types";
import "@aws-amplify/ui-react/styles.css";
import { Description } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid2,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField
} from "@mui/material";
import Pin from "assets/icons/estate_location/pin.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { RequestEstate } from "common/interfaces/RequestEstate";
import { JsonMasterSelect } from "components/Parts/Atoms/JsonMasterSelect";
import { ChangeEventValue } from "components/Parts/Atoms/SumoraMap/interfaces";
import { LocationMap } from "components/Parts/Molecules/LocationMap";
import { isEmpty } from "lodash";
import { memo } from "react";
import styles from "./RequestInputDialog.module.scss";

const LocationMapWithInfo = memo(
  (props: {
    estate: Partial<RequestEstate>;
    location: google.maps.LatLngLiteral | null;
    center: google.maps.LatLngLiteral;
    zoom: number;
    places: google.maps.places.PlaceResult | undefined;
    busy: boolean;
    onMapChange: (value: ChangeEventValue) => void;
    onMapReady: (map: google.maps.Map) => void;
    onLocationChange: (value: google.maps.LatLng) => void;
  }) => {
    const {
      estate,
      location,
      center,
      zoom,
      places,
      busy,
      onMapChange,
      onMapReady,
      onLocationChange
    } = props;

    return (
      <Grid2 container className={styles.mapWrapper}>
        <Grid2 size={12}>
          <LocationMap
            mapDivId="location-map"
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            draggable={!busy}
            onChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
            className={styles.map}
          />
        </Grid2>
        <Grid2 size={12}>
          <small>
            緯度: {estate.latitude} / 経度: {estate.longitude}
          </small>
        </Grid2>
      </Grid2>
    );
  }
);

export const EstateTypeField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>種別</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"estate_types"}
              value={estate.estateType}
              fullWidth
              onChange={(value) =>
                onEstateChange({ estateType: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
        <FormHelperText>
          種別を選択すると、種別に応じた入力項目が表示されます。
        </FormHelperText>
      </FormControl>
    );
  }
);

export const MediationKindField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>取引態様</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"mediation_kinds"}
              value={estate.mediationKind}
              fullWidth
              onChange={(value) =>
                onEstateChange({ mediationKind: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const CurrentStatusField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>現況</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"current_statuses"}
              estateTypeForCurrentStatus={
                estate.estateType as EstateTypeNameEnum
              }
              value={estate.currentStatus}
              fullWidth
              onChange={(value) =>
                onEstateChange({ currentStatus: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const GroundTypeField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>地目</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"ground_types"}
              value={estate.groundType}
              fullWidth
              onChange={(value) =>
                onEstateChange({ groundType: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const BldConditionTypeField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>建築条件</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"bld_condition_types"}
              value={estate.bldConditionType}
              fullWidth
              onChange={(value) =>
                onEstateChange({ bldConditionType: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const StructureField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>構造</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"structures"}
              value={estate.structure}
              fullWidth
              onChange={(value) =>
                onEstateChange({ structure: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const BuildingFloorsField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>建物階数</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"building_floors"}
              value={estate.buildingFloors}
              fullWidth
              onChange={(value) =>
                onEstateChange({ buildingFloors: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const PurposeBsField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    setFatal: (error?: string | null | undefined) => void;
  }) => {
    const { estate, onEstateChange, setFatal } = props;

    return (
      <FormControl className={styles.formControl} fullWidth size="small">
        <FormLabel className={styles.formLabel}>種目</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <JsonMasterSelect
              jsonName={"purpose_bs"}
              value={estate.purposeBs}
              fullWidth
              onChange={(value) =>
                onEstateChange({ purposeBs: value ?? undefined })
              }
              setFatal={setFatal}
              onOptionsLoad={undefined}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const EstatePriceField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>価格</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.estatePrice ?? ""}
              placeholder="9999万円"
              onChange={(event) =>
                onEstateChange({
                  estatePrice: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const CompletionMonthField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>{`築年月${
          estate.estateType === EstateTypeNameEnum.新築建売 ? "（予定）" : ""
        }`}</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.completionMonth ?? ""}
              placeholder={new Date()
                .toLocaleDateString()
                .slice(0, 7)
                .replace("/", "年")
                .replace("/", "月")}
              onChange={(event) =>
                onEstateChange({
                  completionMonth: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const MansionNameField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>マンション名称</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.mansionName ?? ""}
              placeholder=""
              onChange={(event) =>
                onEstateChange({
                  mansionName: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const SquareOrTsuboAreaField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>
          {`${
            estate.estateType === EstateTypeNameEnum.マンション
              ? "専有"
              : "敷地"
          }`}
          面積
        </FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.squareOrTsuboArea ?? ""}
              placeholder="99坪、99㎡ など"
              onChange={(event) =>
                onEstateChange({
                  squareOrTsuboArea: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const TotalAreaField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>延床面積</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.totalArea ?? ""}
              placeholder="99㎡ など"
              onChange={(event) =>
                onEstateChange({
                  totalArea: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const LayoutField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>間取り</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.layout ?? ""}
              placeholder="4LDK など"
              onChange={(event) =>
                onEstateChange({
                  layout: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const FloorField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>所在階</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.floor ?? ""}
              placeholder="3階 など"
              onChange={(event) =>
                onEstateChange({
                  floor: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const RoomField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>号室</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.room ?? ""}
              placeholder="5号室 など"
              onChange={(event) =>
                onEstateChange({
                  room: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const ParkingField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>駐車場</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.parking ?? ""}
              placeholder="2台あり など"
              onChange={(event) =>
                onEstateChange({
                  parking: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const ManagementFeeField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>管理費</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.managementFee ?? ""}
              placeholder="10000円"
              onChange={(event) =>
                onEstateChange({
                  managementFee: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const RepairCostField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>修繕積立金</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.repairCost ?? ""}
              placeholder="10000円"
              onChange={(event) =>
                onEstateChange({
                  repairCost: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const OtherCostField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>その他費用</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.otherCost ?? ""}
              placeholder="10000円"
              onChange={(event) =>
                onEstateChange({
                  otherCost: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const BuildingFloorsForBusinessField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>建物階数</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              fullWidth
              disabled={busy}
              value={estate.buildingFloors ?? ""}
              placeholder="10階建 など"
              onChange={(event) =>
                onEstateChange({
                  buildingFloors: event.target.value ?? undefined
                })
              }
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const RemarksField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
  }) => {
    const { estate, busy, onEstateChange } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>備考</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <TextField
              multiline
              rows={4}
              fullWidth
              disabled={busy}
              value={estate.remarks || ""}
              onChange={(event) =>
                onEstateChange({
                  remarks: event.target.value ?? undefined
                })
              }
              placeholder="他の情報等があれば記入してください"
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

export const LocationField = memo(
  (props: {
    estate: Partial<RequestEstate>;
    busy: boolean;
    location: google.maps.LatLngLiteral | null;
    center: google.maps.LatLngLiteral;
    zoom: number;
    places: google.maps.places.PlaceResult | undefined;
    onEstateChange: (estate: Partial<RequestEstate>) => void;
    onAddressToMapButtonClick: (
      event: React.MouseEvent<HTMLButtonElement>
    ) => void;
    onMapChange: (value: ChangeEventValue) => void;
    onMapReady: (map: google.maps.Map) => void;
    onLocationChange: (value: google.maps.LatLng) => void;
  }) => {
    const {
      estate,
      busy,
      location,
      center,
      zoom,
      places,
      onEstateChange,
      onAddressToMapButtonClick,
      onMapChange,
      onMapReady,
      onLocationChange
    } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel} id="location-label">
          所在地
        </FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.place}>
            <Box className={styles.field}>
              <TextField
                id="place-searcher"
                fullWidth
                disabled={busy}
                placeholder="所在地を入力"
                value={estate.name ?? ""}
                onChange={(event) =>
                  onEstateChange({
                    name: event.target.value ?? undefined
                  })
                }
              />
            </Box>
            <FormControl
              className={`${styles.formControl} ${styles.addressToMapButtonWrapper}`}
            >
              <Button
                disabled={busy || isEmpty(estate.name)}
                variant="outlined"
                onClick={onAddressToMapButtonClick}
                className={styles.addressToMapButton}
              >
                <Box component="span" className={styles.buttonIcon}>
                  <Box component="img" src={Pin} />
                </Box>
                位置取得
              </Button>
            </FormControl>
          </Box>
          <Box component="ul" className={styles.ki_maru_list}>
            <Box component="li">ピンを動かして物件所在地を教えてください</Box>
          </Box>
          <LocationMapWithInfo
            estate={estate}
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            busy={busy}
            onMapChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
          />
        </Box>
      </FormControl>
    );
  }
);

export const AttachesField = memo(
  (props: {
    filesRef: React.MutableRefObject<StorageFile[]>;
    humanFileSize: (size: number) => string;
  }) => {
    const { filesRef, humanFileSize } = props;

    return (
      <FormControl className={styles.formControl} fullWidth>
        <FormLabel className={styles.formLabel}>添付資料</FormLabel>
        <Box className={styles.fieldWrapper}>
          <Box className={styles.field}>
            <StorageManager
              acceptedFileTypes={[
                "image/*",
                "application/pdf",
                "text/plain",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ]}
              path={`reports/`}
              autoUpload={false}
              maxFileCount={3}
              isResumable
              displayText={{
                dropFilesText: "ここにファイルをドロップ",
                browseFilesText: "ファイルを選択"
              }}
              components={{
                FileListHeader: () => null,

                FileList: memo((props) => {
                  filesRef.current = props.files;

                  return (
                    <Box>
                      <List dense>
                        {props.files.map((file, index) =>
                          file.file ? (
                            <ListItem
                              key={index}
                              className={styles.fileListItem}
                            >
                              <ListItemIcon className={styles.fileListItemIcon}>
                                {file.isImage ? (
                                  <img
                                    src={URL.createObjectURL(file.file)}
                                    alt={file.file.name}
                                  />
                                ) : (
                                  <Description />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={file.file.name}
                                secondary={humanFileSize(file.file.size)}
                              />
                            </ListItem>
                          ) : null
                        )}
                      </List>
                    </Box>
                  );
                }),

                FileListFooter: memo((props) => {
                  return (
                    <div className="amplify-storagemanager__previewer__footer">
                      <div className="amplify-storagemanager__previewer__actions">
                        <Button
                          className={styles.clearButton}
                          onClick={() => props.onClearAll()}
                        >
                          クリア
                        </Button>
                      </div>
                    </div>
                  );
                })
              }}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);
