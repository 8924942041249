import { Close } from "@mui/icons-material";
import { Alert, AlertTitle, IconButton } from "@mui/material";
import { MainContext } from "common/interfaces/MainContext";
import { MainContextContainer } from "components/Main";
import { Login } from "components/Pages/Login";
import { Confirm } from "components/Parts/Molecules/Confirm";
import { ProgressBackdrop } from "components/Parts/Molecules/ProgressBackdrop";
import { memo } from "react";
import { AgreementDialog } from "../AgreementDialog";
import styles from "./Auth.module.scss";
import { useAuthHooks } from "./hooks";
import { AuthProps } from "./interfaces";

export const Auth = memo((props: AuthProps) => {
  const {
    user,
    userInfo,
    groups,
    company,
    showConfirmSignOut,
    showRecommendSignOut,
    busy,
    authUser,

    fatalMessage,
    setFatal,

    logoVisible,
    setLogoVisible,

    signOut,
    authStatus,

    location,

    isAuthenticated,
    isAgreed,
    isReadyToUse,

    showFatal,

    onChangeSignInState,
    onInvokeSignOut,

    onSignOutClick,
    onConfirmSignOutClose,
    onConfirmSignOutCloseClick,
    onRecommendSignOutClose,
    onRecommendSignOutCloseClick,
    onSessionMissing,
    onCloseFatal
  } = useAuthHooks();

  const mainContext: MainContext = {
    authStatus,
    authUser,

    user,
    company,

    userInfo,
    groups,

    isAuthenticated,
    isAgreed,
    isReadyToUse,

    logoVisible,

    setFatal,
    setLogoVisible,

    signOut,

    onSignOutClick,
    onChangeSignInState,
    onInvokeSignOut,
    onSessionMissing
  };

  return (
    <>
      <MainContextContainer.Provider value={mainContext}>
        {!isAuthenticated &&
        !location.pathname.startsWith("/pub") &&
        !location.pathname.startsWith("/about") &&
        !location.pathname.startsWith("/license") ? (
          // 未認証の場合は、ログインページを表示
          <Login />
        ) : (
          props.children
        )}
      </MainContextContainer.Provider>
      {showFatal && (
        <Alert
          className={styles.alert}
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onCloseFatal}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>エラー</AlertTitle>
          {fatalMessage}
        </Alert>
      )}
      <Confirm
        open={showConfirmSignOut}
        content="ログアウトします。"
        onClose={onConfirmSignOutClose}
        onOkClick={onInvokeSignOut}
        onCloseClick={onConfirmSignOutCloseClick}
      ></Confirm>
      <Confirm
        open={showRecommendSignOut}
        content="ログイン期限が切れました。ログアウトします。"
        onClose={onRecommendSignOutClose}
        onOkClick={onInvokeSignOut}
        onCloseClick={onRecommendSignOutCloseClick}
      ></Confirm>
      {isAgreed === undefined ? null : (
        <AgreementDialog
          open={isAuthenticated && !isAgreed}
          mainContext={mainContext}
          errorMessage=""
          onOkButtonClick={(value) => {
            if (value.User?.agreement) {
              window.location.href = `${window.location.href}`;
            }
          }}
        />
      )}
      <ProgressBackdrop open={busy} />
    </>
  );
});
