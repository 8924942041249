import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2,
  ThemeProvider
} from "@mui/material";
import Memo from "assets/icons/sticky_note/ic_menu_memo.svg";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { isEmpty } from "lodash";
import React, { useMemo } from "react";
import { Confirm } from "../../Molecules/Confirm";
import { ProgressBackdrop } from "../../Molecules/ProgressBackdrop";
import styles from "./StickyNoteInputDialog.module.scss";
import { useInputFields } from "./fields";
import { useStickyNoteInputDialogHooks } from "./hooks";
import { StickyNoteInputDialogProps } from "./interfaces";

const idPrefix = "sticky-note-input-dialog";

export const StickyNoteInputDialog = React.memo(
  (props: StickyNoteInputDialogProps) => {
    const hooks = useStickyNoteInputDialogHooks(props);

    const {
      theme,

      setStickyNoteErrorMessages,

      errorMessage,
      busy,
      confirm,
      forceDisable,

      onOkButtonClick,
      onConfirmDeleteClick,
      onConfirmDeleteCloseClick,
      onConfirmDeleteClose,
      onClose,

      nodeRef
    } = hooks;

    const allListItems = useInputFields(props, hooks)();

    const fields = useMemo(
      () =>
        allListItems.map((inputField) =>
          inputField.value ? (
            <Grid2 size={12} key={inputField.title}>
              {inputField.value}
            </Grid2>
          ) : null
        ),
      [allListItems]
    );

    if (props.open) {
      return (
        <Dialog
          open={props.open}
          onClose={onClose}
          aria-labelledby={`${idPrefix}-title`}
          aria-describedby={`${idPrefix}-description`}
          maxWidth={false}
          disableEscapeKeyDown
          ref={nodeRef}
          PaperProps={{ className: styles.dialogPaper }}
          slotProps={{ backdrop: { className: styles.backdrop } }}
        >
          <DialogHeader
            id={`${idPrefix}-title`}
            icon={
              <Box className={styles.icon}>
                <Box component="img" src={Memo} />
              </Box>
            }
          />
          <Collapse in={props.errorMessage !== "" || errorMessage !== ""}>
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>エラーが発生しました！</AlertTitle>
              {props.errorMessage}
              {errorMessage}
            </Alert>
          </Collapse>
          <Collapse
            in={
              Object.entries(setStickyNoteErrorMessages).filter(
                (kv) => typeof kv[1] === typeof "" && !isEmpty(kv[1])
              ).length !== 0
            }
          >
            <Alert severity="error" className={styles.alert}>
              <AlertTitle>入力エラーがあります。</AlertTitle>
            </Alert>
          </Collapse>
          <DialogContent
            id={`${idPrefix}-description`}
            className={styles.dialogContent}
          >
            <Grid2 container rowSpacing={2} className={styles.fieldsWrapper}>
              <ThemeProvider theme={theme}>{fields}</ThemeProvider>
            </Grid2>
          </DialogContent>
          <DialogActions className={styles.actions}>
            <Button
              variant="contained"
              className={styles.cancelButton}
              disabled={busy || forceDisable}
              onClick={props.onClose}
            >
              キャンセル
            </Button>
            <Button
              fullWidth
              className={styles.okButton}
              variant="contained"
              disabled={forceDisable || busy}
              onClick={onOkButtonClick}
            >
              {props.okButtonCaption}
            </Button>
          </DialogActions>
          <ProgressBackdrop open={busy || props.busy || false} />
          <Confirm
            open={confirm}
            content="メモを削除します。"
            onClose={onConfirmDeleteClose}
            onCloseClick={onConfirmDeleteCloseClick}
            onOkClick={onConfirmDeleteClick}
          />
        </Dialog>
      );
    } else {
      return null;
    }
  }
);
