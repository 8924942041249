import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { JsonMaster } from "common/interfaces/JsonMaster";
import { useJsonMasterHooks } from "hooks/jsonMasterHooks";
import { useReferenceStyles } from "hooks/referenceStyles";
import { useCallback, useEffect, useMemo } from "react";

export const useJsonMasterSelectHooks = <T extends JsonMaster>(
  jsonName: string | null,

  onValueChange: ((value: string | null | undefined) => void) | undefined,
  setFatal: ((error?: string | null | undefined) => void) | undefined,
  onOptionsLoad: ((options: T[]) => void) | undefined
) => {
  const { jsonMasters, setJsonMasters } = useJsonMasterHooks<T>(
    jsonName,
    setFatal
  );

  const currentStatuses = useMemo(
    () => ({
      [EstateTypeNameEnum.土地]: jsonMasters.filter((x) =>
        ["更地", "古家付", "造成中", "現況渡し", "解体更地渡し"].includes(
          x.name ?? ""
        )
      ),
      [EstateTypeNameEnum.新築建売]: jsonMasters.filter((x) =>
        ["建築中", "完成済"].includes(x.name ?? "")
      ),
      [EstateTypeNameEnum.中古戸建]: jsonMasters.filter((x) =>
        [
          "現況渡し",
          "空",
          "使用中",
          "居住中",
          "賃貸中",
          "リフォーム中"
        ].includes(x.name ?? "")
      )
    }),
    [jsonMasters]
  );

  const referenceStyles = useReferenceStyles();

  const onChange = useCallback(
    (event: SelectChangeEvent, child: React.ReactNode) => {
      onValueChange?.(event.target.value);
    },
    [onValueChange]
  );

  useEffect(() => {
    if (onOptionsLoad) {
      onOptionsLoad(jsonMasters);
    }
  }, [jsonMasters, onOptionsLoad]);

  return {
    jsonMasters,
    currentStatuses,
    setJsonMasters,
    referenceStyles,

    onChange
  } as const;
};
