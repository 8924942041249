import { SxProps, Theme, ThemeOptions } from "@mui/material";

export const globalTheme: ThemeOptions = {
  typography: { fontFamily: "din-2014, fot-tsukuardgothic-std, sans-serif;" },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "2.5rem"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        positionFixed: {
          boxShadow: "none"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: `"Lato", fot-tsukuardgothic-std, sans-serif`
        }
      }
    },
    MuiGrid2: {
      styleOverrides: {
        root: {
          "&.MuiGrid2-container": {
            width: "100%"
          }
        }
      }
    }
  }
};

export const globalSx: SxProps<Theme> = {
  ".amplify-label": {
    fontWeight: 700
  },
  ".amplify-field-group--horizontal": {
    fontWeight: 700,
    borderRadius: "20px",
    border: "solid black 2px",

    input: {
      border: "none",

      "&:focus": {
        outline: "none",
        boxShadow: "none"
      }
    }
  },
  ".amplify-field__show-password": {
    border: "none",
    borderRadius: "20px"
  },

  "--amplify-components-button-primary-background-color":
    "rgba(255, 240, 0, 1)",
  "--amplify-components-button-primary-hover-background-color":
    "rgba(235, 220, 0, 1)",
  "--amplify-components-button-primary-focus-background-color":
    "rgba(235, 220, 0, 1)",
  "--amplify-components-button-primary-active-background-color":
    "rgba(235, 220, 0, 1)",
  "--amplify-components-button-primary-color": "black",
  "--amplify-components-button-primary-hover-color": "black",
  "--amplify-components-button-primary-focus-color": "black",
  "--amplify-components-button-primary-active-color": "black",
  ".amplify-button--primary": {
    borderRadius: "20px",
    border: "solid black 2px",
    fontWeight: "700 !important"
  },

  "--amplify-components-button-link-color": "rgba(50, 50, 50, 1)",
  "--amplify-components-button-link-hover-background-color":
    "rgba(255, 255, 190, 1)",

  "--amplify-components-authenticator-router-border-width": "4px",
  "--amplify-components-authenticator-router-border-color": "black",
  "[data-amplify-authenticator] [data-amplify-router]": {
    borderRadius: "20px"
  }
};
