import { getUrl } from "aws-amplify/storage";
import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { SchoolKindEnum } from "common/enums/SchoolKindEnum";
import { SchoolArea } from "common/interfaces/SchoolArea";
import geobuf from "geobuf";
import Pbf from "pbf";
import { invokeGetPresignedUrlForAnonymous } from "../api/s3";

export const getSchoolAreas = async (
  prefCode: string,
  cityCode: string,
  schoolKind: SchoolKindEnum,
  forAnonymous: boolean
): Promise<SchoolArea | undefined> => {
  const jsonKey = `public/json/${prefCode}/schools/${cityCode}${
    schoolKind === SchoolKindEnum.ESchool ? "e" : "j"
  }.buf`;
  const jsonUrl = await (forAnonymous
    ? invokeGetPresignedUrlForAnonymous(jsonKey, "unauthenticated")
    : getUrl({
        path: jsonKey
      }).then((value) => value.url.href)
  ).catch((err) => undefined);

  if (jsonUrl) {
    const axios = setupCache(Axios.create());
    const response = await axios
      .get<ArrayBuffer>(jsonUrl, {
        responseType: "arraybuffer"
      })
      .catch((err) => undefined);

    if (response) {
      const pbf = new Pbf(response.data);

      const geoJson = geobuf.decode(pbf);

      return { ...geoJson } as SchoolArea;
    }
  }

  return undefined;
};
