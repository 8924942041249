import { getUrl } from "@aws-amplify/storage";
import { Announce } from "API";
import {
  getAnnounce as invokeGetAnnounce,
  listAnnouncesByYm as invokeListAnnouncesByYm
} from "common/functions/api/announces";
import { add, sub } from "date-fns";
import { useCallback } from "react";

export const useAnnounceHooks = (
  setFatal: (error?: string | null | undefined) => void
) => {
  const listAnnounces = useCallback(async () => {
    const yms: string[] = [];

    const tmpCreatedAtFrom = add(new Date(), {
      months: -3
    });
    const tmpCreatedAtTo = new Date();

    for (
      let ym = tmpCreatedAtFrom;
      ym <= tmpCreatedAtTo;
      ym = add(ym, { months: 1 })
    ) {
      yms.push(
        sub(ym, { minutes: new Date().getTimezoneOffset() })
          .toISOString()
          .slice(0, 7)
      );
    }

    const announces = await invokeListAnnouncesByYm(yms).catch((error) => {
      setFatal(error);
      return [] as Announce[];
    });

    return announces;
  }, [setFatal]);

  const getAnnounce = useCallback(
    async (id: string | null | undefined) => {
      const res = await invokeGetAnnounce(id || undefined).catch((error) => {
        setFatal(error);
        return undefined;
      });

      if (res?.getAnnounce) {
        const announce = res.getAnnounce;

        const imgs = announce.body?.match(/<img[^>]+>/g) ?? [];

        for (const img of imgs) {
          const path = img.match(/src="(.+?)"/)?.[1];

          if (path) {
            const result = await getUrl({
              path
            });

            if (result.url.href) {
              announce.body = announce.body?.replace(path, result.url.href);
            }
          }
        }

        return announce;
      }
    },
    [setFatal]
  );

  return {
    listAnnounces,
    getAnnounce
  } as const;
};
