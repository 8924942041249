import { Box } from "@mui/material";
import { ReactComponent as LogoSvg } from "assets/icons/logo_tate.svg";
import React from "react";

export const Logo = React.memo((props: { styles: Record<string, string> }) => {
  const { styles } = props;

  return (
    <>
      <Box className={styles.logo}>
        <LogoSvg />
      </Box>
      <Box className={styles.poweredBy}>Powered by TAMURA BUILDS</Box>
    </>
  );
});
