import { downloadData } from "aws-amplify/storage";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const useReportFileHooks = () => {
  const params = useParams();
  const { objectFolder, objectKey } = params;

  useEffect(() => {
    if (objectKey) {
      downloadData({
        path: `reports/${objectFolder}/${objectKey}`
      }).result.then((result) => {
        result.body.blob().then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");

          link.href = url;
          link.download = objectKey;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
      });
    }
  }, [objectFolder, objectKey]);
};
