import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import { HeaderBar } from "components/Parts/Organisms/HeaderBar";
import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./AboutPage.module.scss";
import { useAboutHooks } from "./hooks";
import { AboutProps } from "./interfaces";

export const AboutPage = React.memo((props: AboutProps) => {
  const { mainContext, docs } = useAboutHooks();

  const markDowns: JSX.Element[] = [];

  let cnt = 0;

  for (const markDown of docs) {
    markDowns.push(
      <ReactMarkdown
        key={`doc${(++cnt).toString()}`}
        components={{ h1: "h2", h2: "h3", h3: "h4", h4: "h5" }}
        className={styles.markDown}
      >
        {markDown}
      </ReactMarkdown>
    );
  }

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <MainWrapper>{markDowns}</MainWrapper>
    </ContentMain>
  );
});
