import { SearchModeEnum } from "common/enums/SearchModeEnum";
import { About } from "components/Pages/About";
import { Archive } from "components/Pages/Archive";
import { CheckEstates } from "components/Pages/CheckEstates";
import { Detail } from "components/Pages/Detail";
import { Home } from "components/Pages/Home";
import { License } from "components/Pages/License";
import { NotFound } from "components/Pages/NotFound";
import { ReportFile } from "components/Pages/ReportFile";
import { Search } from "components/Pages/Search";
import { memo } from "react";
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "*",
    Component: () => (
      <Routes>
        <Route
          key="search"
          path="/search"
          element={<Search searchMode={SearchModeEnum.estate} />}
        ></Route>
        <Route
          key="draft"
          path="/draft"
          element={<Search searchMode={SearchModeEnum.draft} />}
        ></Route>
        <Route
          key="condominium"
          path="/condominium"
          element={<Search searchMode={SearchModeEnum.condominium} />}
        ></Route>
        <Route
          key="detail"
          path="/detail/:id"
          element={<Detail forAnonymous={false} />}
        ></Route>
        <Route
          key="pub"
          path="/pub/:id"
          element={<Detail forAnonymous={true} />}
        ></Route>
        <Route key="archive" path="/archive" element={<Archive />}></Route>
        <Route
          key="check_estates"
          path="/check_estates"
          element={<CheckEstates ownDraft={false} />}
        ></Route>
        <Route
          key="check_estates_draft"
          path="/check_estates_draft"
          element={<CheckEstates ownDraft={true} />}
        ></Route>
        <Route key="about" path="/about" element={<About />}></Route>
        <Route key="license" path="/license" element={<License />}></Route>
        <Route key="home" index element={<Home />}></Route>
        <Route key="not_found" path="/*" element={<NotFound />} />
        <Route
          key="report_file"
          path="/report_file/:objectFolder/:objectKey"
          element={<ReportFile />}
        />
      </Routes>
    )
  }
]);

export const Routings = memo(() => <RouterProvider router={router} />);
