import { Chip } from "@mui/material";
import { AnnounceLevel } from "API";
import React from "react";
import styles from "./LevelChip.module.scss";

export const LevelChip = React.memo((props: { level: AnnounceLevel }) => {
  return (
    <Chip
      label={
        props.level === AnnounceLevel.INFO
          ? "お知らせ"
          : props.level === AnnounceLevel.WARN
          ? "注意"
          : props.level === AnnounceLevel.IMPORTANT
          ? "重要"
          : undefined
      }
      className={
        props.level === AnnounceLevel.INFO
          ? styles.infoChip
          : props.level === AnnounceLevel.WARN
          ? styles.warnChip
          : props.level === AnnounceLevel.IMPORTANT
          ? styles.importantChip
          : undefined
      }
    />
  );
});
