import { getUrl } from "aws-amplify/storage";
import axios from "axios";
import { SchoolKindEnum } from "common/enums/SchoolKindEnum";
import { SchoolPoint } from "common/interfaces/SchoolPoint";

export const getSchoolPoints = async (
  schoolPointParameters: {
    prefCode: string;
    cityCode: string;
    schoolKind: SchoolKindEnum;
  }[]
): Promise<SchoolPoint[] | undefined> => {
  const schoolPoints: SchoolPoint[] = [];

  for (const schoolPointParameter of schoolPointParameters) {
    const jsonUrl = await getUrl({
      path: `public/json/${schoolPointParameter.prefCode}/schools/${
        schoolPointParameter.cityCode
      }${
        schoolPointParameter.schoolKind === SchoolKindEnum.ESchool ? "e" : "j"
      }p.json`
    })
      .then((value) => value.url.href)
      .catch((err) => undefined);

    if (jsonUrl) {
      const response = await axios
        .get<SchoolPoint[]>(jsonUrl)
        .catch((err) => undefined);

      if (response) {
        schoolPoints.push(...response.data);
      }
    }
  }

  return schoolPoints;
};
