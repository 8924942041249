import { FormHelperText, MenuItem, Select } from "@mui/material";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { JsonMaster } from "common/interfaces/JsonMaster";
import React from "react";
import { useJsonMasterSelectHooks } from "./hooks";
import { JsonMasterSelectProps } from "./interfaces";

const JsonMasterSelectImplements = <T extends JsonMaster>(
  props: JsonMasterSelectProps<T>
) => {
  const {
    jsonMasters,
    currentStatuses,

    onChange
  } = useJsonMasterSelectHooks<T>(
    props.jsonName,
    props.onChange,
    props.setFatal,
    props.onOptionsLoad
  );

  return (
    <>
      <Select
        value={props.value ?? ""}
        label={props.label}
        onChange={onChange}
        fullWidth={props.fullWidth}
      >
        <MenuItem key="None" value="">
          -
        </MenuItem>
        {(props.estateTypeForCurrentStatus === EstateTypeNameEnum.土地 ||
        props.estateTypeForCurrentStatus === EstateTypeNameEnum.分譲地
          ? currentStatuses[EstateTypeNameEnum.土地]
          : props.estateTypeForCurrentStatus === EstateTypeNameEnum.新築建売
          ? currentStatuses[EstateTypeNameEnum.新築建売]
          : props.estateTypeForCurrentStatus !== undefined
          ? currentStatuses[EstateTypeNameEnum.中古戸建]
          : jsonMasters
        ).map((x) => (
          <MenuItem key={x.id} value={x.name}>
            {x.name}
          </MenuItem>
        ))}
      </Select>
      {props.helperText === undefined ? null : (
        <FormHelperText>{props.helperText}</FormHelperText>
      )}
    </>
  );
};

export const JsonMasterSelect = React.memo(
  JsonMasterSelectImplements
) as typeof JsonMasterSelectImplements;
