import { Box, Icon, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ic_copy from "assets/icons/ic_copy.svg";
import React from "react";
import styles from "./CopyLinkButton.module.scss";
import { useCopyLinkButtonHooks } from "./hooks";
import { CopyLinkButtonProps } from "./interfaces";

export const CopyLinkButton = React.memo((props: CopyLinkButtonProps) => {
  const { open, onClick, handleTooltipClose } = useCopyLinkButtonHooks(props);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
        className: styles.copyLinkButtonToolTipPopper,
        sx: {
          ".MuiTooltip-tooltip": {
            color: "black",
            backgroundColor: "white",
            border: "1px solid rgba(235, 220, 0, 1)",
            fontSize: "90%"
          }
        }
      }}
      onClose={handleTooltipClose}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={props.toolTipText ?? "コピーしました"}
    >
      <Box component="span">
        <IconButton
          aria-label="リンクをコピー"
          style={props.style}
          className={`${styles.copyLinkButton} ${props.className ?? ""}`}
          onClick={onClick}
        >
          <Icon className={styles.icon}>
            <img src={ic_copy} alt="copy" />
          </Icon>
        </IconButton>
      </Box>
    </Tooltip>
  );
});
