import { MainContextContainer } from "components/Main";
import { useCallback, useContext, useMemo, useState } from "react";
import { HeaderBarProps } from "./interfaces";

export const useHeaderBarHooks = (props: HeaderBarProps) => {
  const mainContext = useContext(MainContextContainer);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const logoVisible = useMemo(
    () => mainContext.logoVisible,
    [mainContext.logoVisible]
  );

  const onMenuOpenClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onReloadClick = useCallback(() => {
    setAnchorEl(null);
    window.location.href = `${window.location.href}`;
  }, []);

  const onSignOutClick = useCallback(() => {
    setAnchorEl(null);
    mainContext.onSignOutClick();
  }, [mainContext]);

  return {
    anchorEl,
    open,
    logoVisible,
    onMenuOpenClick,
    onMenuClose,
    onReloadClick,
    onSignOutClick
  } as const;
};
