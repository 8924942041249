import { Announce } from "API";
import { useAnnounceHooks } from "hooks/announceHooks";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AnnounceDialogDialogProps } from "./interfaces";

export const useAnnounceDialogDialogHooks = (
  props: AnnounceDialogDialogProps
) => {
  const nodeRef = useRef(null);

  const [announce, setAnnounce] = useState<Announce | null | undefined>(
    undefined
  );
  const { getAnnounce } = useAnnounceHooks(props.mainContext.setFatal);
  const [errorMessage, setErrorMessage] = useState("");
  const [busy, setBusy] = useState(false);

  const onOkButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
      props.onOkButtonClick();
    },
    [props]
  );

  useEffect(() => {
    getAnnounce(props.announceId)
      .then((res) => {
        setAnnounce(res);
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }, [getAnnounce, props.announceId]);

  return {
    announce,
    errorMessage,
    setErrorMessage,
    busy,
    setBusy,

    onOkButtonClick,
    nodeRef
  } as const;
};
