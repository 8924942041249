import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import { binaryToGeojson } from "@loaders.gl/gis";
import type { BinaryFeatureCollection } from "@loaders.gl/schema";
import { centerOfMass, polygon } from "@turf/turf";
import { youtoColors } from "common/consts/youtoColors";
import { CustomMVTLayer } from "common/functions/classes/CustomMVTLayer";
import { convertUseDistrictName } from "common/functions/jsonMasters/useDistricts";
import { hex2rgb } from "common/functions/utilities";
import { Prefecture } from "common/interfaces/Prefecture";
import { UseDistrictProperty } from "common/interfaces/UseDistrictGeoJSON";
import { TextLayer } from "deck.gl";
import type { Feature, GeoJsonProperties, Geometry, Polygon } from "geojson";
import { useCallback, useContext, useEffect, useRef } from "react";
import { SearchMapContext } from "../../context";

export const useHooks = (on: boolean, drawingPrefs: Prefecture[]) => {
  const { map } = useContext(SearchMapContext);
  const googleMapsOverlay = useRef<GoogleMapsOverlay | undefined>(undefined);
  const cashedTiles = useRef<{ [url: string]: { buf: ArrayBuffer } }>({});

  const constructTileLayer = useCallback(() => {
    const mvtLayer = new CustomMVTLayer({
      data: "json/use_districts/tiles/{z}/{x}/{y}.pbf",
      id: "youtoTilesLayer",
      tileSize: 256,
      getFillColor: (feature: Feature) => {
        const properties = feature?.properties;
        const useDistrictCode = properties?.use_district_code;
        const youtoFillColor =
          youtoColors.find((x) => x.code === useDistrictCode)?.fillColor ||
          "#000000";

        const rgb = hex2rgb(youtoFillColor);

        return [rgb[0], rgb[1], rgb[2], 127];
      },
      getLineColor: (feature: Feature) => {
        const properties = feature?.properties;
        const useDistrictCode = properties?.use_district_code;
        const youtoStrokeColor =
          youtoColors.find((x) => x.code === useDistrictCode)?.fillColor ||
          "#000000";

        const rgb = hex2rgb(youtoStrokeColor);

        return [rgb[0], rgb[1], rgb[2], 255];
      },
      lineWidthMinPixels: 1,
      lineWidthMaxPixels: 1,
      pickable: true,
      autoHighlight: false,
      highlightColor: [255, 255, 255, 255]
    });

    mvtLayer.mockGML = process.env.REACT_APP_MOCK_GML === "true";
    mvtLayer.cachedTiles = cashedTiles;

    const textLayer = new CustomMVTLayer({
      data: "json/use_districts/tiles/{z}/{x}/{y}.pbf",
      id: "youtoTextTilesLayer",
      tileSize: 256,
      renderSubLayers: (props) => {
        if (props.data) {
          const key = `${props.tile.index.x}_${props.tile.index.y}_${props.tile.index.z}`;

          const features = props.data as BinaryFeatureCollection;

          const textData: ({
            text: string;
            position: number[];
            color: number[];
          } | null)[] = [];

          if (features.polygons) {
            const geojsonData = binaryToGeojson(
              props.data as BinaryFeatureCollection
            );

            textData.push(
              ...(props.tile.index.z <= 13
                ? []
                : (geojsonData as Feature<Geometry, UseDistrictProperty>[])
                    .map((feature) => {
                      const useDistrictName = convertUseDistrictName(
                        feature.properties
                      );

                      if (feature.geometry.type === "Polygon") {
                        const builtPolygon: Feature<
                          Polygon,
                          GeoJsonProperties
                        > = polygon(feature.geometry.coordinates);

                        const centroid = centerOfMass(builtPolygon.geometry);

                        return {
                          text: useDistrictName,
                          position: [
                            centroid.geometry.coordinates[0],
                            centroid.geometry.coordinates[1]
                          ],
                          color: [0, 0, 0, 80]
                        };
                      }

                      return null;
                    })
                    .filter((x) => x !== null))
            );

            return new TextLayer({
              ...props,
              id: `youtoTileLayerText_${key}`,
              data: textData,
              getText: (data: { text: string }) => data.text,
              getPosition: (data: { position: [number, number] }) =>
                data.position,
              getTextAnchor: "middle",
              getAlignmentBaseline: "center",
              getSize: 12,
              getColor: (data: { color: [number, number, number, number] }) =>
                data.color,
              fontFamily: "din-2014, fot-tsukuardgothic-std, sans-serif",
              fontWeight: 600,
              characterSet: "auto",
              tileSize: 256
            });
          }
        }

        return null;
      }
    });

    textLayer.delay = 200;
    textLayer.cachedTiles = cashedTiles;

    return new GoogleMapsOverlay({
      layers: [mvtLayer, textLayer]
    });
  }, []);

  useEffect(() => {
    if (on && map) {
      const tileLayer = constructTileLayer();
      tileLayer.setMap(map);
      googleMapsOverlay.current = tileLayer;
    } else if (!on) {
      googleMapsOverlay.current?.setMap(null);
    }
  }, [constructTileLayer, map, on]);

  return { map } as const;
};
