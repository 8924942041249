import { Box } from "@mui/material";
import { MainContextContainer } from "components/Main";
import { MainWrapper } from "components/Parts/Atoms/MainWrapper";
import { ContentMain } from "components/Parts/Organisms/ContentMain";
import { HeaderBar } from "components/Parts/Organisms/HeaderBar";
import React, { useContext } from "react";
import styles from "./NotFoundPage.module.scss";
import { NotFoundProps } from "./interfaces";

export const NotFoundPage = React.memo((props: NotFoundProps) => {
  const mainContext = useContext(MainContextContainer);

  return (
    <ContentMain>
      <HeaderBar
        authStatus={mainContext.authStatus}
        authUser={mainContext.authUser}
        user={mainContext.user}
        company={mainContext.company}
        userInfo={mainContext.userInfo}
        groups={mainContext.groups}
      />
      <MainWrapper>
        <Box className={styles.notFound}>
          <Box>ご指定のページが見つかりません。</Box>
        </Box>
      </MainWrapper>
    </ContentMain>
  );
});
