import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid2,
  TextField
} from "@mui/material";
import Pin from "assets/icons/estate_location/pin.svg";
import Apartment from "assets/icons/ic_map_type_apartment.svg";
import Block from "assets/icons/ic_map_type_block.svg";
import Business from "assets/icons/ic_map_type_business.svg";
import Ground from "assets/icons/ic_map_type_ground.svg";
import New from "assets/icons/ic_map_type_new.svg";
import Used from "assets/icons/ic_map_type_used.svg";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { Status } from "common/interfaces/Status";
import { CompanyWithSearchStringAutocomplete } from "components/Parts/Atoms/CompanyAutocomplete";
import { CompanyWithRecNoAutocomplete } from "components/Parts/Atoms/CompanyWithRecNoAutocomplete";
import { JsonMasterSwitchButtons } from "components/Parts/Atoms/JsonMasterSwitchButtons";
import { LocationMap } from "components/Parts/Molecules/LocationMap";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import styles from "./EstateLocationInputDialog.module.scss";
import { EstateLocationInputMode } from "./enums";
import { EstateLocationInputDialogHooksType } from "./hooks";
import { EstateLocationInputDialogProps } from "./interfaces";

export const useInputFields = (
  props: EstateLocationInputDialogProps,
  {
    mainContext,

    estate,

    defaultCompanyRecNo,
    selectedCompany,

    companyErrorMessage,

    busy,
    forceDisable,

    statuses,

    location,
    center,
    zoom,
    places,

    address,

    onStatusChange,
    onSelectedCompanyChange,

    onAddressChange,
    onAddressToMapButtonClick,

    onMapChange,
    onMapReady,

    onLocationChange
  }: EstateLocationInputDialogHooksType
) => {
  return useCallback(() => {
    const isCondominium = estate.estateType === EstateTypeNameEnum.マンション;

    const locationMap = (
      <Grid2 container className={styles.mapWrapper}>
        <Grid2 size={12}>
          <LocationMap
            mapDivId="location-map"
            location={location}
            center={center}
            zoom={zoom}
            places={places}
            draggable={
              !busy &&
              props.mode !== EstateLocationInputMode.Delete &&
              !isCondominium
            }
            onChange={onMapChange}
            onMapReady={onMapReady}
            onLocationChange={onLocationChange}
            className={styles.map}
          />
        </Grid2>
        <Grid2 size={12}>
          <small>
            緯度: {estate.latitude} / 経度: {estate.longitude}
          </small>
        </Grid2>
        <Grid2 size={12}>
          <small>
            都道府県: {estate.prefecture || "？"} / 市区町村:{" "}
            {estate.city || "？"} / 町域: {estate.area || "？"}
          </small>
        </Grid2>
      </Grid2>
    );

    const inputFields: { title: string; value: JSX.Element }[] = [
      {
        title: "情報",
        value: (
          <Box className={styles.bkn_info}>
            <Box className={styles.box}>
              <Box className={styles.icon}>
                <Box
                  component="img"
                  src={
                    estate.estateType === EstateTypeNameEnum.土地
                      ? Ground
                      : estate.estateType === EstateTypeNameEnum.分譲地
                      ? Block
                      : estate.estateType === EstateTypeNameEnum.新築建売
                      ? New
                      : estate.estateType === EstateTypeNameEnum.中古戸建
                      ? Used
                      : estate.estateType === EstateTypeNameEnum.マンション
                      ? Apartment
                      : estate.estateType === EstateTypeNameEnum.事業用
                      ? Business
                      : undefined
                  }
                />
              </Box>
              <Box className={styles.dtl}>
                <Box className={styles.box}>
                  <Box className={styles.tit}>ID（物件番号）</Box>
                  <Box className={styles.tx}>{estate.id}</Box>
                </Box>
                <Box className={styles.box}>
                  <Box className={styles.tit}>物件名称</Box>
                  <Box className={styles.tx}>{estate.name}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      },
      {
        title: "-1",
        value: <Box className={styles.separator} />
      },
      {
        title: "ステータス",
        value: (
          <JsonMasterSwitchButtons<Status>
            id="status"
            label="ステータス"
            master={statuses}
            value={estate.status}
            disabled={
              forceDisable ||
              busy ||
              props.mode === EstateLocationInputMode.Delete
            }
            onChange={onStatusChange}
          />
        )
      },
      {
        title: "-2",
        value: <Box className={styles.separator} />
      },
      {
        title: "位置",
        value: (
          <FormControl fullWidth>
            <FormLabel className={styles.formLabel} id="location-label">
              物件の位置を調整
              {isCondominium ? (
                <Box component="span" className={styles.warning}>
                  {" "}
                  （マンションは位置情報を調整できません）
                </Box>
              ) : (
                ""
              )}
            </FormLabel>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.place}>
                <Box className={styles.field}>
                  <TextField
                    id="place-searcher"
                    fullWidth
                    disabled={
                      forceDisable ||
                      busy ||
                      props.mode === EstateLocationInputMode.Delete ||
                      isCondominium
                    }
                    placeholder="〒、住所の一部等を入力"
                    value={address}
                    onChange={onAddressChange}
                  />
                </Box>
                <FormControl>
                  <Button
                    disabled={
                      forceDisable ||
                      busy ||
                      isEmpty(estate.fullAddress) ||
                      props.mode === EstateLocationInputMode.Delete ||
                      isCondominium
                    }
                    variant="outlined"
                    onClick={onAddressToMapButtonClick}
                    className={styles.addressToMapButton}
                  >
                    <Box component="span" className={styles.buttonIcon}>
                      <Box component="img" src={Pin} />
                    </Box>
                    位置取得
                  </Button>
                </FormControl>
              </Box>
              <Box component="ul" className={styles.ki_maru_list}>
                <Box component="li">ピンを動かして位置を調整してください</Box>
              </Box>
              {locationMap}
            </Box>
          </FormControl>
        )
      },
      {
        title: "-3",
        value: <Box className={styles.separator} />
      },
      {
        title: "業者",
        value: (
          <FormControl fullWidth>
            <FormLabel className={styles.formLabel} id="company-label">
              業者
            </FormLabel>
            <Box className={styles.fieldWrapper}>
              <Box className={`${styles.field} ${styles.company}`}>
                {defaultCompanyRecNo ? (
                  <CompanyWithRecNoAutocomplete
                    multiple={false}
                    label=" "
                    value={selectedCompany}
                    disabled={
                      forceDisable ||
                      busy ||
                      props.mode === EstateLocationInputMode.Delete ||
                      estate.status === StatusNameEnum.マンションM
                    }
                    disableClearable={true}
                    recNo={defaultCompanyRecNo}
                    errorMessage={companyErrorMessage || ""}
                    onChange={onSelectedCompanyChange}
                    setFatal={mainContext.setFatal}
                  />
                ) : (
                  <CompanyWithSearchStringAutocomplete
                    multiple={false}
                    label=" "
                    value={selectedCompany}
                    disabled={
                      forceDisable ||
                      busy ||
                      props.mode === EstateLocationInputMode.Delete ||
                      estate.status === StatusNameEnum.マンションM
                    }
                    disableClearable={true}
                    errorMessage={companyErrorMessage || ""}
                    onChange={onSelectedCompanyChange}
                    setFatal={mainContext.setFatal}
                  />
                )}
              </Box>
            </Box>
          </FormControl>
        )
      }
    ];

    return inputFields;
  }, [
    estate.estateType,
    estate.fullAddress,
    estate.latitude,
    estate.longitude,
    estate.prefecture,
    estate.city,
    estate.area,
    estate.id,
    estate.name,
    estate.status,
    forceDisable,
    busy,
    props.mode,
    onAddressToMapButtonClick,
    location,
    center,
    zoom,
    places,
    onMapChange,
    onMapReady,
    onLocationChange,
    statuses,
    onStatusChange,
    address,
    onAddressChange,
    defaultCompanyRecNo,
    selectedCompany,
    companyErrorMessage,
    onSelectedCompanyChange,
    mainContext.setFatal
  ]);
};
