export enum ReportKindEnum {
  "位置情報が違う" = 1,
  "サイトにアクセスできない" = 2,
  "その他" = 3
}

export enum ReportKindNameEnum {
  "位置情報が違う" = "位置情報が違う",
  "サイトにアクセスできない" = "サイトにアクセスできない",
  "その他" = "その他"
}

export enum ReportKindNameEnumForRequest {
  "登録依頼" = "登録依頼"
}
