import { Badge, Box, createTheme, Grid2, ThemeProvider } from "@mui/material";
import { ReactComponent as ListSvg } from "assets/images/ic_top_list.svg";
import { ReactComponent as MapSvg } from "assets/images/ic_top_map.svg";
import { ReactComponent as PlusSvg } from "assets/images/ic_top_plus.svg";
import { ReactComponent as ShakeHandsSvg } from "assets/images/ic_top_shake_hands.svg";
import React from "react";
import { NavigateFunction } from "react-router-dom";

export const Menu = React.memo(
  (props: {
    styles: Record<string, string>;
    navigate: NavigateFunction;
    onRequestInputDialogOpen: () => void;
    forSmall?: boolean;
    estatesCount?: number;
  }) => {
    const { styles, navigate, estatesCount } = props;
    const forLarge = !(props.forSmall ?? false);
    const badgeTheme = createTheme({
      components: {
        MuiBadge: {
          styleOverrides: {
            badge: {
              fontSize: "1.2rem",
              borderRadius: "50%",
              transform: "scale(1) translate(25%, -50%)",
              padding: "10px 10px",
              height: "3em",
              width: "3em"
            }
          }
        }
      }
    });

    return (
      <Box className={styles.menu}>
        <Grid2 container spacing={2}>
          <Grid2
            size={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => (window.location.href = "/search")}
            >
              <Box className={styles.menuIcon}>
                <MapSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>マップ</Box>
              {forLarge && <Box className={styles.remark}></Box>}
            </Box>
          </Grid2>
          <Grid2
            size={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => navigate("check_estates")}
            >
              <Box className={styles.menuIcon}>
                <ListSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>物件一覧</Box>
            </Box>
          </Grid2>
          <Grid2
            size={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={() => navigate("check_estates_draft")}
            >
              <ThemeProvider theme={badgeTheme}>
                <Badge
                  color="warning"
                  badgeContent={estatesCount}
                  max={999}
                  component="div"
                  className={styles.menuBadge}
                >
                  <Box className={styles.menuIcon}>
                    <ShakeHandsSvg className={styles.menuIconSvg} />
                  </Box>
                  <Box className={styles.caption}>
                    ご協力を
                    <br />
                    お願いします
                  </Box>
                  {forLarge && (
                    <Box className={styles.remark}>
                      貴社の物件について
                      <br />
                      位置を教えてください！
                    </Box>
                  )}
                </Badge>
              </ThemeProvider>
            </Box>
          </Grid2>
          <Grid2
            size={forLarge ? 3 : 12}
            className={styles.menuButtonContainer}
          >
            <Box
              className={styles.menuButton}
              onClick={props.onRequestInputDialogOpen}
            >
              <Box className={styles.menuIcon}>
                <PlusSvg className={styles.menuIconSvg} />
              </Box>
              <Box className={styles.caption}>
                物件登録
                <br />
                依頼
              </Box>
              {forLarge && (
                <Box className={styles.remark}>
                  物件をご登録ください
                  <br />
                  （掲載料無料）
                </Box>
              )}
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    );
  }
);
