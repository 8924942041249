import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  ThemeProvider,
  Tooltip
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { jaJP } from "@mui/x-data-grid/locales";
import { ReactComponent as ToolEdit } from "assets/icons/estate_info/tool_edit.svg";
import { ReactComponent as ToolLink } from "assets/icons/estate_info/tool_link.svg";
import { ReactComponent as ToolPrint } from "assets/icons/estate_info/tool_print.svg";
import { ReactComponent as ToolReport } from "assets/icons/estate_info/tool_report.svg";
import { ReactComponent as ToolTrash } from "assets/icons/estate_info/tool_trash.svg";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { calcOldPrimitive } from "common/functions/estates";
import { MinimalEstate } from "common/queries/minimalEstates";
import { isEmpty } from "lodash";
import React from "react";
import { EstateItemIcon } from "../EstateItemIcon";
import styles from "./EstatesList.module.scss";
import { useEstateListHooks } from "./hooks";
import { EstatesListProps } from "./interfaces";

export const EstatesList = React.memo((props: EstatesListProps) => {
  const {
    toolTipTheme,
    dataGridTheme,
    progressTheme,
    selectedEstatesForPrint,
    isAdministrator,
    isDomain,
    onDisplayEstate,
    onSelectEstateForPrint,
    onClearSelectedEstatesForPrint,
    onPrintSelectedEstates
  } = useEstateListHooks(props);

  const columns: GridColDef<MinimalEstate>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 244,
      renderCell: (params) => (
        <Box className={styles.idWrapper}>
          <EstateItemIcon estate={params.row} />
          <Box className={styles.id}>{params.value}</Box>
          <ThemeProvider theme={toolTipTheme}>
            <Tooltip title="報告" placement="top" followCursor>
              <Box component="span">
                <IconButton
                  className={styles.operationButton}
                  disabled={props.disabled}
                  onClick={() => {
                    props.onEstateClick(params.row as MinimalEstate, "report");
                  }}
                >
                  <Box
                    className={
                      props.disabled ? styles.disabled : styles.reportable
                    }
                  >
                    <ToolReport />
                  </Box>
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="編集" placement="top" followCursor>
              <Box component="span">
                <IconButton
                  className={styles.operationButton}
                  disabled={
                    props.disabled ||
                    (!isAdministrator && !isDomain(params.row as MinimalEstate))
                  }
                  onClick={() => {
                    props.onEstateClick(params.row as MinimalEstate, "update");
                  }}
                >
                  <Box
                    className={
                      props.disabled ||
                      (!isAdministrator &&
                        !isDomain(params.row as MinimalEstate))
                        ? styles.disabled
                        : styles.editable
                    }
                  >
                    <ToolEdit />
                  </Box>
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="削除" placement="top" followCursor>
              <Box component="span">
                <IconButton
                  className={styles.operationButton}
                  disabled={
                    props.disabled ||
                    (!isAdministrator && !isDomain(params.row as MinimalEstate))
                  }
                  onClick={() => {
                    props.onEstateClick(params.row as MinimalEstate, "delete");
                  }}
                >
                  <Box
                    className={
                      props.disabled ||
                      (!isAdministrator &&
                        !isDomain(params.row as MinimalEstate))
                        ? styles.disabled
                        : styles.deletable
                    }
                  >
                    <ToolTrash />
                  </Box>
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="詳細" placement="top" followCursor>
              <Box component="span">
                <IconButton
                  className={styles.operationButton}
                  href={
                    (params.row as MinimalEstate).estateUrl ??
                    (params.row as MinimalEstate).url ??
                    ""
                  }
                  disabled={isEmpty(
                    (params.row as MinimalEstate).estateUrl ??
                      (params.row as MinimalEstate).url
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    className={
                      isEmpty(
                        (params.row as MinimalEstate).estateUrl ??
                          (params.row as MinimalEstate).url
                      )
                        ? styles.disabled
                        : styles.referable
                    }
                  >
                    <ToolLink />
                  </Box>
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip title="印刷" placement="top" followCursor>
              <Box component="span">
                <IconButton
                  className={`print-button ${styles.operationButton}`}
                  onClick={() => onDisplayEstate(params.row as MinimalEstate)}
                >
                  <Box className={styles.printable}>
                    <ToolPrint />
                  </Box>
                </IconButton>
              </Box>
            </Tooltip>
          </ThemeProvider>
        </Box>
      )
    },
    {
      field: "select",
      headerName: "複数印刷",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Box className={styles.selectWrapper}>
          <Checkbox
            className={`${styles.selectCheckbox} ${
              selectedEstatesForPrint.length >= 10 &&
              !selectedEstatesForPrint.includes(
                (params.row as MinimalEstate).id ?? ""
              )
                ? styles.disabled
                : ""
            }`}
            size="small"
            disabled={
              selectedEstatesForPrint.length >= 10 &&
              !selectedEstatesForPrint.includes(
                (params.row as MinimalEstate).id ?? ""
              )
            }
            checked={selectedEstatesForPrint.includes(
              (params.row as MinimalEstate).id ?? ""
            )}
            onChange={(_, checked) =>
              onSelectEstateForPrint(params.row as MinimalEstate, checked)
            }
          />
        </Box>
      )
    },
    {
      field: "status",
      headerName: "ステータス",
      width: 100,
      renderCell: (params) =>
        params.value === StatusNameEnum.下書き
          ? StatusNameEnum.位置不明
          : params.value
    },
    {
      field: "companies",
      headerName: "業者名",
      width: 200,
      renderCell: (params) => {
        const estate = params.row as MinimalEstate;
        return [
          estate.companyName,
          estate.subCompanyName,
          estate.subCompany2Name
        ]
          .filter((x) => x)
          .join(", ");
      }
    },
    { field: "mediationKind", headerName: "媒介種別", width: 100 },
    { field: "estateType", headerName: "物件種別", width: 100 },
    {
      field: "estatePrice",
      headerName: "価格(万円)",
      width: 100,
      align: "right"
    },
    {
      field: "squareArea",
      headerName: "面積(㎡)",
      width: 100,
      align: "right"
    },
    { field: "layout", headerName: "間取り", width: 100 },
    {
      field: "completionMonth",
      headerName: "築年数",
      width: 100,
      align: "right",
      renderCell: (params) => {
        if (params.value) {
          return calcOldPrimitive(params.value);
        }

        return "";
      }
    },
    { field: "fullAddress", headerName: "物件所在地", width: 300 },
    { field: "prefecture", headerName: "都道府県", width: 100 },
    { field: "city", headerName: "市区町村", width: 100 },
    // { field: "latitude", headerName: "緯度", width: 120 },
    // { field: "longitude", headerName: "経度", width: 120 },
    { field: "name", headerName: "物件名称", width: 300 }
  ];

  return (
    <Box id="estate-list" className={styles.estatesList}>
      {props.busy ? (
        <Box className={styles.progress}>
          <ThemeProvider theme={progressTheme}>
            <CircularProgress color={"primary"} />
          </ThemeProvider>
        </Box>
      ) : (
        <>
          <ThemeProvider theme={dataGridTheme}>
            <DataGrid
              rows={props.estates}
              columns={columns}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              density="compact"
              className={styles.dataGrid}
            />
          </ThemeProvider>
          <Box className={styles.multiPrint}>
            <Box
              className={`${styles.button} ${styles.clear} ${
                selectedEstatesForPrint.length > 0 ? "" : styles.disabled
              }`}
              onClick={onClearSelectedEstatesForPrint}
            >
              選択を全解除
            </Box>
            <Box
              className={`${styles.button} ${
                selectedEstatesForPrint.length > 0 ? "" : styles.disabled
              }`}
              onClick={onPrintSelectedEstates}
            >
              選択した物件を印刷
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
});
