import { AnnouncementOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2
} from "@mui/material";
import { AnnounceLevel } from "API";
import { DialogHeader } from "components/Parts/Atoms/DialogHeader";
import { LevelChip } from "components/Parts/Atoms/LevelChip";
import React from "react";
import { ProgressBackdrop } from "../../Molecules/ProgressBackdrop";
import styles from "./AnnounceDialog.module.scss";
import { useAnnounceDialogDialogHooks } from "./hooks";
import { AnnounceDialogDialogProps as AnnounceDialogProps } from "./interfaces";

const idPrefix = "announce-dialog";

export const AnnounceDialog = React.memo((props: AnnounceDialogProps) => {
  const {
    announce,
    errorMessage,
    busy,

    onOkButtonClick,
    nodeRef
  } = useAnnounceDialogDialogHooks(props);

  if (props.open) {
    return (
      <Dialog
        open={props.open}
        aria-labelledby={`${idPrefix}-title`}
        aria-describedby={`${idPrefix}-description`}
        maxWidth={false}
        disableEscapeKeyDown
        ref={nodeRef}
        className={styles.announceDialog}
        PaperProps={{ className: styles.dialogPaper }}
        slotProps={{ backdrop: { className: styles.backdrop } }}
      >
        <Collapse in={errorMessage !== ""}>
          <Alert severity="error" className={styles.alert}>
            <AlertTitle>エラーが発生しました！</AlertTitle>
            {errorMessage}
          </Alert>
        </Collapse>
        <DialogHeader
          id={`${idPrefix}-title`}
          icon={
            <Box className={styles.headerIcon}>
              <AnnouncementOutlined />
            </Box>
          }
        />
        <DialogContent
          id={`${idPrefix}-description`}
          className={styles.content}
        >
          <Grid2 container rowSpacing={2} className={styles.container}>
            <Grid2 size={12}>
              <Box className={styles.subject}>
                <LevelChip level={announce?.level ?? AnnounceLevel.INFO} />
                {announce?.subject}
              </Box>
              <Box className={styles.createdAt}>
                {announce?.createdAt
                  ? new Date(announce.createdAt)
                      .toLocaleDateString("ja-JP")
                      .split("/")
                      .map((v) => v.padStart(2, "0"))
                      .join("/")
                  : "----/--/--"}
              </Box>
              <Box
                component="div"
                dangerouslySetInnerHTML={{ __html: announce?.body ?? "" }}
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions className={styles.actions}>
          <Button
            className={`${styles.button} ${styles.ok}`}
            fullWidth
            variant="contained"
            disabled={busy}
            onClick={onOkButtonClick}
          >
            閉じる
          </Button>
        </DialogActions>
        <ProgressBackdrop open={busy || props.busy || false} />
      </Dialog>
    );
  } else {
    return null;
  }
});
