import { AltRoute, Close, ExpandMore } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CloseIcon from "@mui/icons-material/Close";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import {
  Button,
  CardActions,
  CardContent,
  Grid2,
  IconButton,
  Paper
} from "@mui/material";
import { Box } from "@mui/system";
import { BoxShadowCard } from "components/Parts/Atoms/BoxShadowCard";
import { LabeledSwitch } from "components/Parts/Atoms/LabeledSwitch";
import React, { MouseEventHandler, TouchEventHandler, useMemo } from "react";
import { isMobile } from "react-device-detect";
import Draggable from "react-draggable";
import { CommonDialogIconButton } from "../../Atoms/CommonDialogComponents";
import { QRCodeView } from "../QRCodeView";
import styles from "./SearchRouteDialog.module.scss";
import { useSearchRouteDialogHooks } from "./hooks";
import { SearchRouteDialogProps } from "./interfaces";

export const SearchRouteDialog = React.memo((props: SearchRouteDialogProps) => {
  const {
    mainRef,
    showQRCode,
    expand,
    setExpand,
    onClose,
    onCloseClick,
    onShowQRCodeClick
  } = useSearchRouteDialogHooks();

  const { list, url } = useMemo(() => {
    const list: JSX.Element[] = [];
    let url = "https://www.google.com/maps/dir/?api=1&hl=ja";

    const listItem = (
      title: string,
      location: google.maps.LatLng,
      address?: string
    ) => {
      let info: JSX.Element | null = null;

      if (address) {
        info = <>{address.replace(/日本、〒...-.... /, "")}</>;
      } else {
        info = (
          <>
            {`緯度: ${location.lat()}`}
            <br />
            {`経度: ${location.lng()}`}
          </>
        );
      }

      list.push(
        <Paper className={styles.item} key={title}>
          <Grid2 container spacing={2}>
            <Grid2 size={2}>{title}</Grid2>
            <Grid2 size={8}>
              <Box
                className={styles.info}
                onClick={() => {
                  if (props.map) {
                    props.map.setCenter(location);
                  }
                }}
              >
                {info}
              </Box>
            </Grid2>
            <Grid2 size={2}>
              <CommonDialogIconButton
                onClick={() => {
                  props.onRouteLocationDelete(location);
                }}
              >
                <CloseIcon />
              </CommonDialogIconButton>
            </Grid2>
          </Grid2>
        </Paper>
      );
    };

    const resultListItem = (result: google.maps.DirectionsLeg) => {
      list.push(
        <Box className={styles.route} key={result.start_address}>
          <Grid2 container spacing={2}>
            <Grid2 size={5} className={styles.routeInfo}>
              {result.distance?.text}
            </Grid2>
            <Grid2 size={2} className={styles.routeInfo}>
              <ArrowDownwardIcon />
            </Grid2>
            <Grid2 size={5} className={styles.routeInfo}>
              {result.duration?.text}
            </Grid2>
          </Grid2>
        </Box>
      );
    };

    if (props.routeLocationsResult) {
      // 結果がある場合
      const legs = props.routeLocationsResult.routes
        .flatMap((x) => x.legs)
        .filter((x) => x.distance?.value !== 0 && x.duration?.value !== 0);

      if (legs.length > 0) {
        if (props.travelMode) {
          url = `${url}&travelmode=${props.travelMode?.toLowerCase()}`;
        }

        // 始点
        listItem("始点", legs[0].start_location, legs[0].start_address);
        resultListItem(legs[0]);
        url = `${url}&origin=${legs[0].start_location.toUrlValue()}`;

        // 経由地
        for (let i = 1; i < legs.length; i++) {
          listItem(
            `経由 (${i})`,
            legs[i].start_location,
            legs[i].start_address
          );
          resultListItem(legs[i]);
          if (i === 1) {
            url = `${url}&waypoints=${legs[i].start_location.toUrlValue()}`;
          } else {
            url = `${url}|${legs[i].start_location.toUrlValue()}`;
          }
        }

        // 終点
        listItem(
          "終点",
          legs[legs.length - 1].end_location,
          legs[legs.length - 1].end_address
        );
        url = `${url}&destination=${legs[
          legs.length - 1
        ].end_location.toUrlValue()}`;

        // 概要
        const totalDistance = legs
          .map((x) => x.distance?.value || 0)
          .reduce((sum, x) => sum + x);

        const totalDuration = legs
          .map((x) => x.duration?.value || 0)
          .reduce((sum, x) => sum + x);

        const hours = Math.round(totalDuration / 60 / 60);
        const minutes = Math.round((totalDuration % (60 * 60)) / 60);

        list.unshift(
          <Paper className={styles.resultItem} key="result">
            総距離: {`${totalDistance / 1000} km`}
            <br />
            総時間: {`${hours}時間${minutes}分`}
            <br />
            <Box className={styles.actions}>
              <Button
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                fullWidth
                variant="contained"
                className={`${styles.button} ${styles.googleMap}`}
              >
                Googleマップ
              </Button>
              <Button
                fullWidth
                variant="contained"
                className={`${styles.button} ${styles.qrCode}`}
                onClick={onShowQRCodeClick}
              >
                QRコード
              </Button>
            </Box>
          </Paper>
        );
      }
    } else {
      if (props.routeLocations.from) {
        listItem("始点", props.routeLocations.from);
      }

      for (let i = 0; i < props.routeLocations.waypoints.length; i++) {
        listItem(`経由 (${i + 1})`, props.routeLocations.waypoints[i]);
      }

      if (props.routeLocations.to) {
        listItem("終点", props.routeLocations.to);
      }
    }

    return { list, url };
  }, [onShowQRCodeClick, props]);

  if (props.show) {
    const searchable = list.length < 2;

    return (
      <>
        <Draggable
          nodeRef={mainRef}
          handle=".draggable-div"
          onMouseDown={props.onMouseDown}
        >
          <Box ref={mainRef} className={styles.cardWrapper}>
            <BoxShadowCard
              className={`${styles.dialogPaper} ${
                expand ? styles.expand : ""
              } ${isMobile ? styles.mobile : ""}`}
              style={props.style}
            >
              <Box className={styles.header}>
                <AltRoute className="draggable-div" />
                <IconButton
                  className={styles.closeButton}
                  size="small"
                  aria-label="close"
                  onClick={
                    props.onClose as MouseEventHandler<HTMLButtonElement>
                  }
                  onTouchStart={
                    props.onClose as TouchEventHandler<HTMLButtonElement>
                  }
                >
                  <Close />
                </IconButton>
                <IconButton
                  className={styles.expandButton}
                  size="small"
                  onClick={() => setExpand((x) => !x)}
                  onTouchStart={() => setExpand((x) => !x)}
                >
                  <ExpandMore
                    className={`${styles.expandIcon} ${
                      expand ? styles.expand : ""
                    }`}
                  />
                </IconButton>
              </Box>
              <CardContent
                className={`${styles.content} ${expand ? styles.expand : ""}`}
              >
                <Box className={styles.routes}>
                  {list.length === 0 && (
                    <Box component="small">
                      ※マップを{isMobile ? "長押し" : "右クリック"}
                      して出発地・目的地を設定
                    </Box>
                  )}
                  {list}
                </Box>
              </CardContent>
              <CardActions className={styles.switch}>
                <LabeledSwitch
                  label={`${expand ? "経由地の" : ""}ルート最適化`}
                  value={props.optimizeWaypoints}
                  onChange={(event) =>
                    props.onOptimizeWaypointsChange(event.target.checked)
                  }
                />
              </CardActions>
              <CardActions className={styles.buttons}>
                <Button
                  fullWidth
                  className={`${styles.button} ${
                    props.travelMode === google.maps.TravelMode.WALKING
                      ? styles.active
                      : styles.deactive
                  }`}
                  variant="contained"
                  disabled={searchable}
                  onClick={() =>
                    props.onSearchButtonClick(
                      props.travelMode !== google.maps.TravelMode.WALKING
                        ? google.maps.TravelMode.WALKING
                        : null
                    )
                  }
                >
                  <DirectionsWalkIcon />
                </Button>
                <Button
                  fullWidth
                  className={`${styles.button} ${
                    props.travelMode === google.maps.TravelMode.DRIVING
                      ? styles.active
                      : styles.deactive
                  }`}
                  variant="contained"
                  disabled={searchable}
                  onClick={() =>
                    props.onSearchButtonClick(
                      props.travelMode !== google.maps.TravelMode.DRIVING
                        ? google.maps.TravelMode.DRIVING
                        : null
                    )
                  }
                >
                  <DriveEtaIcon />
                </Button>
              </CardActions>
            </BoxShadowCard>
          </Box>
        </Draggable>
        <QRCodeView
          open={showQRCode}
          content={`${url}&dir_action=navigate`}
          onClose={onClose}
          onCloseClick={onCloseClick}
        />
      </>
    );
  } else {
    return null;
  }
});
