import { Box, Grid2 } from "@mui/material";
import { MainContext } from "common/interfaces/MainContext";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { HomeProps } from "../interfaces";
import { Info } from "../subComponents/Info";
import { Links } from "../subComponents/Links";
import { Logo } from "../subComponents/Logo";
import { Menu } from "../subComponents/Menu";
import styles from "./SmallHomePageContent.module.scss";

export const SmallHomePageContent = React.memo(
  (
    props: HomeProps & {
      mainContext: MainContext;
      navigate: NavigateFunction;
      onRequestInputDialogOpen: () => void;
    }
  ) => {
    const { mainContext, navigate, onRequestInputDialogOpen } = props;

    return (
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Box className={styles.top}>
            <Logo styles={styles} />
          </Box>
        </Grid2>
        <Grid2 size={12}>
          <Box className={styles.middle}>
            <Info styles={styles} mainContext={mainContext} />
            <Menu
              styles={styles}
              navigate={navigate}
              onRequestInputDialogOpen={onRequestInputDialogOpen}
              forSmall
            />
          </Box>
        </Grid2>
        <Grid2 size={12}>
          <Box className={styles.bottom}>
            <Links
              styles={styles}
              navigate={navigate}
              mainContext={mainContext}
            />
          </Box>
        </Grid2>
      </Grid2>
    );
  }
);
