import {
  area,
  bboxPolygon,
  booleanPointInPolygon,
  centerOfMass,
  difference,
  intersect,
  pointOnFeature,
  polygon,
  simplify,
  union
} from "@turf/turf";
import jSchoolIcon from "assets/icons/ic_map_school_chu.svg";
import eSchoolIcon from "assets/icons/ic_map_school_sho.svg";
import { SchoolKindEnum } from "common/enums/SchoolKindEnum";
import {
  clearFeature,
  clearMarker,
  clearPolygon
} from "common/functions/mapUtilities";
import { SchoolArea, SchoolAreaProperty } from "common/interfaces/SchoolArea";
import { SchoolPoint } from "common/interfaces/SchoolPoint";
import {
  ESchoolFillColor,
  ESchoolStrokeColor,
  JSchoolFillColor,
  JSchoolStrokeColor
} from "components/Parts/Organisms/SearchMap/consts";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  MultiPolygon,
  Polygon
} from "geojson";
import "long-press-event";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { SearchMapContext } from "../../context";
import { SchoolNameView } from "./SchoolNameView";

export const useHooks = (
  schoolAreas: SchoolArea[],
  schoolPoints: SchoolPoint[]
) => {
  const areaBase = 200000;

  const markersLongPressed = useRef(false);

  const schoolAreaFeaturesRef = useRef<google.maps.Data.Feature[]>([]);
  const schoolNamesRef = useRef<SchoolNameView[]>([]);
  const schoolMarkersRef = useRef<google.maps.marker.AdvancedMarkerElement[]>(
    []
  );
  const areaDrawsRef = useRef<google.maps.Polygon[]>([]);

  const idleListenerRef = useRef<google.maps.MapsEventListener | undefined>(
    undefined
  );

  const {
    map,
    fillingSchoolPolygons,
    searchRouteMode,
    onSchoolContextMenu,
    onFillingSchoolPolygonsChange
  } = useContext(SearchMapContext);

  const [mapBounds, setMapBounds] = useState<google.maps.LatLngBounds | null>(
    null
  );

  // SchoolNameの破棄
  const clearSchoolName = useCallback((schoolName: SchoolNameView | null) => {
    if (schoolName) {
      google.maps.event.clearInstanceListeners(schoolName);
      schoolName.setMap(null);
      schoolName = null;
    }
  }, []);

  // 全学校区の破棄
  const clearAllSchoolAreaFeatures = useCallback(
    (map: google.maps.Map | null) => {
      for (const schoolAreaFeature of schoolAreaFeaturesRef.current) {
        clearFeature(map, schoolAreaFeature);
      }

      schoolAreaFeaturesRef.current = [];
    },
    []
  );

  // 全学校名の破棄
  const clearAllSchoolNames = useCallback(() => {
    for (const schoolName of schoolNamesRef.current) {
      clearSchoolName(schoolName);
    }

    schoolNamesRef.current = [];
  }, [clearSchoolName]);

  // 全学校マーカーの破棄
  const clearAllSchoolMarkers = useCallback(() => {
    for (const schoolMarker of schoolMarkersRef.current) {
      clearMarker(schoolMarker);
    }

    schoolMarkersRef.current = [];
  }, []);

  // 全デバッグ用学校名描画ポリゴンの破棄
  const clearAllAreaDraws = useCallback(() => {
    for (const areaDraw of areaDrawsRef.current) {
      clearPolygon(areaDraw);
    }

    areaDrawsRef.current = [];
  }, []);

  // データスタイル構築
  const constructDataStyles = useCallback(() => {
    if (map) {
      map.data.setStyle((feature: google.maps.Data.Feature) => {
        const kind = feature.getProperty("kind");

        switch (kind) {
          case "school": {
            // 学校区の描画スタイル
            const schoolKind = feature.getProperty("school_kind");
            const strokeColor =
              schoolKind === SchoolKindEnum.ESchool
                ? ESchoolStrokeColor
                : JSchoolStrokeColor;
            const strokeOpacity =
              schoolKind === SchoolKindEnum.ESchool ? 1.0 : 1.0;
            const strokeWeight =
              schoolKind === SchoolKindEnum.ESchool ? 4 : 1.5;
            const fillColor =
              schoolKind === SchoolKindEnum.ESchool
                ? ESchoolFillColor
                : JSchoolFillColor;
            const fillOpacity =
              schoolKind === SchoolKindEnum.ESchool ? 0.25 : 0.15;
            const zIndex = schoolKind === SchoolKindEnum.ESchool ? 1000 : 2000;
            const clicked = feature.getProperty("clicked");

            return {
              strokeColor: strokeColor,
              strokeOpacity: strokeOpacity,
              strokeWeight: strokeWeight,
              fillColor: fillColor,
              fillOpacity: clicked ? fillOpacity : 0.0,
              zIndex: zIndex,
              clickable: false
            };
          }
          default: {
            return {};
          }
        }
      });
    }
  }, [map]);

  // 学校名表示構築
  const constructSchoolName = useCallback(
    (
      schoolName: string,
      schoolKind: SchoolKindEnum,
      feature: Feature<Polygon>
    ) => {
      if (map) {
        const SchoolNameView = require("./SchoolNameView").SchoolNameView;
        const builtPolygon: Feature<Polygon, GeoJsonProperties> = polygon(
          feature.geometry.coordinates
        );

        const builtArea = area(builtPolygon.geometry);

        if (builtArea > areaBase) {
          const builtPointOnFeature = pointOnFeature(builtPolygon.geometry);
          const centroid = centerOfMass(builtPolygon.geometry);

          let latLng = new google.maps.LatLng({
            lat: builtPointOnFeature.geometry.coordinates[1] || 0,
            lng: builtPointOnFeature.geometry.coordinates[0] || 0
          });

          if (booleanPointInPolygon(centroid, builtPolygon)) {
            latLng = new google.maps.LatLng({
              lat: centroid.geometry.coordinates[1] || 0,
              lng: centroid.geometry.coordinates[0] || 0
            });
          }

          const schoolNameView = new SchoolNameView(
            latLng,
            schoolName,
            schoolKind
          ) as SchoolNameView;

          schoolNameView.setMap(map);
          return schoolNameView;
        }
      }

      return null;
    },
    [map]
  );

  // Polygon配列共通処理
  const pushToPolygons = useCallback(
    (
      feature: Feature<Polygon | MultiPolygon, GeoJsonProperties>,
      polygons: {
        polygon: Feature<Polygon>;
        schoolAreaProperties: SchoolAreaProperty;
      }[],
      schoolAreaProperties: SchoolAreaProperty
    ) => {
      switch (feature.geometry.type) {
        case "MultiPolygon":
          for (const shared of feature.geometry.coordinates) {
            polygons.push({
              polygon: polygon(shared),
              schoolAreaProperties: schoolAreaProperties
            });
          }
          break;
        case "Polygon":
          polygons.push({
            polygon: polygon(feature.geometry.coordinates),
            schoolAreaProperties: schoolAreaProperties
          });
          break;
      }
    },
    []
  );

  // 学校区描画
  const drawSchoolAreas = useCallback(() => {
    if (map) {
      const clickedSchoolAreaKeys: string[] = [];

      if (schoolAreaFeaturesRef) {
        // クリック済みの学校区を退避
        clickedSchoolAreaKeys.push(
          ...schoolAreaFeaturesRef.current
            .filter((x) => x.getProperty("clicked"))
            .map((x) =>
              [
                `${x.getProperty("city_code")}`.substring(0, 2),
                x.getProperty("school_name")
              ].join("#")
            )
        );

        if (schoolNamesRef) {
          // 学校名削除
          clearAllSchoolNames();
          clearAllAreaDraws();
        }

        // Data削除
        clearAllSchoolAreaFeatures(map);
      }

      for (const schoolArea of schoolAreas) {
        const features = map.data.addGeoJson(schoolArea);

        // 学校名描画
        if (mapBounds) {
          const ne = mapBounds.getNorthEast();
          const sw = mapBounds.getSouthWest();

          const square = bboxPolygon([ne.lng(), ne.lat(), sw.lng(), sw.lat()]);

          // 地図表示領域内のPolygon
          const originalSchoolAreaPolygons: {
            polygon: Feature<Polygon>;
            schoolAreaProperties: SchoolAreaProperty;
          }[] = [];

          for (const schoolAreaFeature of schoolArea.features) {
            if (schoolAreaFeature.geometry.coordinates) {
              for (const coordinates of schoolAreaFeature.geometry
                .coordinates) {
                const builtPolygon = simplify(polygon([coordinates]), {
                  tolerance: 0.00025,
                  highQuality: false
                });

                const builtIntersect = intersect({
                  type: "FeatureCollection",
                  features: [builtPolygon, square]
                } as FeatureCollection<Polygon, GeoJsonProperties>);

                if (builtIntersect) {
                  pushToPolygons(
                    builtIntersect,
                    originalSchoolAreaPolygons,
                    schoolAreaFeature.properties
                  );
                }
              }
            }
          }

          // 共通エリア
          const sharedSchoolAreaPolygons: {
            polygon: Feature<Polygon>;
            schoolAreaProperties: SchoolAreaProperty;
          }[] = [];

          // 共通エリアの合併
          let allShared: Feature<
            Polygon | MultiPolygon,
            GeoJsonProperties
          > | null = null;

          for (let i = 0; i < originalSchoolAreaPolygons.length; i++) {
            const originalSchoolAreaPolygon = originalSchoolAreaPolygons[i];
            for (let j = i + 1; j < originalSchoolAreaPolygons.length; j++) {
              const otherSchoolAreaPolygon = originalSchoolAreaPolygons[j];

              const sharedSchoolName = [
                originalSchoolAreaPolygon.schoolAreaProperties.school_name,
                otherSchoolAreaPolygon.schoolAreaProperties.school_name
              ]
                .sort()
                .join("#");

              const sharedFeature = intersect({
                type: "FeatureCollection",
                features: [
                  originalSchoolAreaPolygon.polygon,
                  otherSchoolAreaPolygon.polygon
                ]
              } as FeatureCollection<Polygon, GeoJsonProperties>);

              if (sharedFeature) {
                pushToPolygons(sharedFeature, sharedSchoolAreaPolygons, {
                  ...originalSchoolAreaPolygon.schoolAreaProperties,
                  school_name: sharedSchoolName
                });

                if (allShared) {
                  const builtUnion: Feature<
                    Polygon | MultiPolygon,
                    GeoJsonProperties
                  > | null = union({
                    type: "FeatureCollection",
                    features: [allShared, sharedFeature]
                  } as FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>);

                  if (builtUnion) {
                    allShared = builtUnion;
                  }
                } else {
                  allShared = sharedFeature;
                }
              }
            }
          }

          // 共通部分除外エリア
          const schoolAreaPolygons: {
            polygon: Feature<Polygon>;
            schoolAreaProperties: SchoolAreaProperty;
          }[] = [];

          for (const originalSchoolAreaPolygon of originalSchoolAreaPolygons) {
            try {
              const remain =
                allShared === null
                  ? originalSchoolAreaPolygon.polygon
                  : difference({
                      type: "FeatureCollection",
                      features: [originalSchoolAreaPolygon.polygon, allShared]
                    } as FeatureCollection<Polygon | MultiPolygon, GeoJsonProperties>);

              if (remain) {
                pushToPolygons(
                  remain,
                  schoolAreaPolygons,
                  originalSchoolAreaPolygon.schoolAreaProperties
                );
              }
            } catch {
              pushToPolygons(
                originalSchoolAreaPolygon.polygon,
                schoolAreaPolygons,
                originalSchoolAreaPolygon.schoolAreaProperties
              );
            }
          }

          for (const schoolAreaFeature of [
            ...schoolAreaPolygons,
            ...sharedSchoolAreaPolygons
          ]) {
            const schoolNameView = constructSchoolName(
              schoolAreaFeature.schoolAreaProperties.school_name,
              schoolAreaFeature.schoolAreaProperties.school_kind,
              schoolAreaFeature.polygon
            );

            if (schoolNameView) {
              schoolNamesRef.current.push(schoolNameView);

              // // デバッグ用
              // const paths =
              //   schoolAreaFeature.polygon.geometry.coordinates[0].map(
              //     (x) => ({
              //       lng: x[0],
              //       lat: x[1]
              //     })
              //   );

              // const areaDraw = new google.maps.Polygon({
              //   paths: paths,
              //   strokeColor: "#FF0000",
              //   strokeOpacity: 0.8,
              //   strokeWeight: 1,
              //   fillColor: "#FF0000",
              //   fillOpacity: 0.0
              // });

              // areaDraw.setMap(map);

              // areaDraws.push(areaDraw);
            }
          }
        }

        schoolAreaFeaturesRef.current.push(...features);

        // クリック済みの学校区を塗りつぶす
        schoolAreaFeaturesRef.current.forEach((feature) => {
          const key = [
            `${feature.getProperty("city_code")}`.substring(0, 2),
            feature.getProperty("school_name")
          ].join("#");

          if (clickedSchoolAreaKeys.includes(key)) {
            feature.setProperty("clicked", true);
          }
        });
      }
    }
  }, [
    clearAllAreaDraws,
    clearAllSchoolAreaFeatures,
    clearAllSchoolNames,
    constructSchoolName,
    map,
    mapBounds,
    pushToPolygons,
    schoolAreas
  ]);

  // 学校ポイントマーカー描画
  const drawSchoolPoints = useCallback(() => {
    if (map) {
      // 再描画不要の学校ポイント
      const existMarkers = schoolMarkersRef.current.filter(
        (existMarker) =>
          existMarker.map === map &&
          schoolPoints.find(
            (newPosition) =>
              newPosition.school_kind ===
                Number(
                  (existMarker.content as HTMLDivElement | undefined)?.dataset
                    .schoolName ?? "0"
                ) &&
              newPosition.latitude ===
                (typeof existMarker.position?.lat === "number"
                  ? existMarker.position.lat
                  : existMarker.position?.lat()) &&
              newPosition.longitude ===
                (typeof existMarker.position?.lng === "number"
                  ? existMarker.position.lng
                  : existMarker.position?.lng())
          ) !== undefined
      );

      // 学校ポイント削除
      const deletableMarkers = schoolMarkersRef.current.filter(
        (existMarker) =>
          schoolPoints.find(
            (newPosition) =>
              newPosition.school_kind ===
                Number(
                  (existMarker.content as HTMLDivElement | undefined)?.dataset
                    .schoolName ?? "0"
                ) &&
              newPosition.latitude ===
                (typeof existMarker.position?.lat === "number"
                  ? existMarker.position.lat
                  : existMarker.position?.lat()) &&
              newPosition.longitude ===
                (typeof existMarker.position?.lng === "number"
                  ? existMarker.position.lng
                  : existMarker.position?.lng())
          ) === undefined
      );

      for (const schoolMarker of deletableMarkers) {
        clearMarker(schoolMarker);
      }

      schoolMarkersRef.current = existMarkers;

      // 学校ポイント追加
      const newPoints = schoolPoints.filter(
        (newPosition) =>
          schoolMarkersRef.current.find(
            (existMarker) =>
              newPosition.school_kind ===
                Number(
                  (existMarker.content as HTMLDivElement | undefined)?.dataset
                    .schoolName ?? "0"
                ) &&
              newPosition.latitude ===
                (typeof existMarker.position?.lat === "number"
                  ? existMarker.position.lat
                  : existMarker.position?.lat()) &&
              newPosition.longitude ===
                (typeof existMarker.position?.lng === "number"
                  ? existMarker.position.lng
                  : existMarker.position?.lng())
          ) === undefined
      );

      // クリックイベント追加
      const addClick = (
        schoolPointMarker: google.maps.marker.AdvancedMarkerElement,
        schoolPoint: SchoolPoint
      ) => {
        schoolPointMarker.addListener(
          "click",
          (event: google.maps.MapMouseEvent) => {
            if (searchRouteMode || markersLongPressed.current) {
            } else {
              schoolAreaFeaturesRef.current
                .filter(
                  (x) =>
                    `${x.getProperty("city_code")}`.substring(0, 2) ===
                      schoolPoint.city_code.substring(0, 2) &&
                    x.getProperty("school_name") === schoolPoint.school_name
                )
                .forEach((x) =>
                  x.setProperty("clicked", !(x.getProperty("clicked") || false))
                );

              if (onFillingSchoolPolygonsChange) {
                onFillingSchoolPolygonsChange(
                  schoolAreaFeaturesRef.current.some((x) =>
                    x.getProperty("clicked")
                  )
                );
              }
            }

            event.domEvent.cancelBubble = true;
            event.domEvent.stopPropagation();

            markersLongPressed.current = false;

            return false;
          }
        );
      };

      // コンテキストメニューイベント追加
      const addContextMenu = (
        schoolPointMarker: google.maps.marker.AdvancedMarkerElement
      ) => {
        const position = schoolPointMarker.position;

        if (position && onSchoolContextMenu) {
          schoolPointMarker.element?.addEventListener(
            "contextmenu",
            (event) => {
              onSchoolContextMenu(event, new google.maps.LatLng(position));

              event.stopPropagation();

              markersLongPressed.current = false;

              return false;
            }
          );
        }
      };

      // マウスダウンイベント追加
      const addMouseDown = (
        schoolPointMarker: google.maps.marker.AdvancedMarkerElement
      ) => {
        schoolPointMarker.element?.addEventListener("mousedown", (event) => {
          event.stopPropagation();
          return false;
        });
      };

      // 長押しイベント追加
      const addLongPress = (
        schoolPointMarker: google.maps.marker.AdvancedMarkerElement
      ) => {
        const position = schoolPointMarker.position;

        if (position && onSchoolContextMenu) {
          schoolPointMarker.element?.addEventListener("long-press", (event) => {
            markersLongPressed.current = true;

            onSchoolContextMenu(event, new google.maps.LatLng(position));

            setTimeout(() => {
              markersLongPressed.current = false;
            }, 500);

            event.stopPropagation();

            return false;
          });
        }
      };

      for (const schoolPoint of newPoints) {
        const content = document.createElement("div");
        content.classList.add("school-point-marker");
        content.dataset.schoolName = String(schoolPoint.school_kind);

        content.innerHTML = `
          <img src="${
            schoolPoint.school_kind === SchoolKindEnum.ESchool
              ? eSchoolIcon
              : jSchoolIcon
          }" />
          <div class="school_point_marker_label">${schoolPoint.school_name.replace(
            /学校$/g,
            ""
          )}</div>
        `;

        const schoolPointMarker = new google.maps.marker.AdvancedMarkerElement({
          position: {
            lat: schoolPoint.latitude,
            lng: schoolPoint.longitude
          },
          content: content,
          zIndex: google.maps.Marker.MAX_ZINDEX + 10000
        });

        if (isMobile) {
          schoolPointMarker.element.setAttribute(
            "data-long-press-delay",
            "300"
          );
        }

        addClick(schoolPointMarker, schoolPoint);
        addContextMenu(schoolPointMarker);
        addMouseDown(schoolPointMarker);

        if (isMobile) {
          addLongPress(schoolPointMarker);
        }

        schoolPointMarker.map = map;

        schoolMarkersRef.current.push(schoolPointMarker);
      }
    }
  }, [
    map,
    onFillingSchoolPolygonsChange,
    onSchoolContextMenu,
    schoolPoints,
    searchRouteMode
  ]);

  useEffect(() => {
    if (map) {
      idleListenerRef.current = map.addListener("idle", () => {
        setMapBounds(map.getBounds() as google.maps.LatLngBounds);
      });
    }
  }, [map]);

  useEffect(() => {
    return () => {
      clearAllSchoolAreaFeatures(map);
      clearAllSchoolNames();
      clearAllSchoolMarkers();
      clearAllAreaDraws();

      if (idleListenerRef.current) {
        google.maps.event.removeListener(idleListenerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    constructDataStyles();
  }, [constructDataStyles]);

  useEffect(() => {
    if (map) {
      drawSchoolAreas();
      drawSchoolPoints();
    }
  }, [drawSchoolAreas, drawSchoolPoints, map]);

  useEffect(() => {
    if (!fillingSchoolPolygons) {
      schoolAreaFeaturesRef.current.forEach((x) =>
        x.setProperty("clicked", false)
      );
    }
  }, [fillingSchoolPolygons]);

  return { map } as const;
};
