import {
  CustomMVTLayer,
  ParsedMvtTile
} from "common/functions/classes/CustomMVTLayer";
import { TileLoadProps } from "common/types/TileLoadProps";

export class MojMVTPolygonLayer<
  ExtraProps extends {} = {}
> extends CustomMVTLayer<ExtraProps> {
  async getTileData(loadProps: TileLoadProps): Promise<ParsedMvtTile> {
    const data = await super.getTileData(loadProps);
    (data as any).points = (data as any).lines;

    return data;
  }
}
