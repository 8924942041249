import { GraphQLResult, graphqlOperation } from "@aws-amplify/api-graphql";
import {
  Announce,
  GetAnnounceQuery,
  GetAnnounceQueryVariables,
  ListAnnouncesByYmQuery,
  ListAnnouncesByYmQueryVariables
} from "API";
import { generateClient } from "aws-amplify/api";
import {
  getAnnounce as getAnnounceQuery,
  listAnnouncesByYm as listAnnouncesByYmQuery
} from "graphql/queries";

// 検索を停止させるフラグ
let cancelSearch = false;

export const getAnnounce = async (
  id: string | undefined
): Promise<GetAnnounceQuery | undefined> => {
  if (id) {
    const client = generateClient();
    const announceData = (await client.graphql({
      ...graphqlOperation(getAnnounceQuery, {
        id
      } as GetAnnounceQueryVariables),
      authMode: "userPool"
    })) as GraphQLResult<GetAnnounceQuery>;

    return announceData.data;
  }
};

export const listAnnouncesByYm = async (yms: string[]) => {
  const announces: Announce[] = [];

  const variablesList: ListAnnouncesByYmQueryVariables[] = yms.map((ym) => ({
    ym,
    limit: 1000,
    nextToken: null
  }));

  for (const variables of variablesList) {
    const client = generateClient();

    while (!cancelSearch) {
      if (variables.nextToken === null) {
        delete variables.nextToken;
      }

      const res = (await client.graphql({
        ...graphqlOperation(listAnnouncesByYmQuery, variables),
        authMode: "userPool"
      })) as GraphQLResult<ListAnnouncesByYmQuery>;

      if (res.data?.listAnnouncesByYm?.items) {
        announces.push(
          ...((res.data?.listAnnouncesByYm.items ?? []).filter(
            (x) => x
          ) as Announce[])
        );
      }

      variables.nextToken = res.data?.listAnnouncesByYm?.nextToken || null;

      if (variables.nextToken === null) {
        break;
      }
    }
  }

  announces.sort((a, b) => {
    const order = (a.createdAt || "").localeCompare(b.createdAt || "");

    return order * -1;
  });

  return announces;
};
