import { getUrl } from "aws-amplify/storage";
import axios from "axios";
import { invokeGetPresignedUrlForAnonymous } from "../api/s3";

export const getJsonMaster = async <T>(
  jsonName: string
): Promise<T | undefined> => {
  const jsonUrl = await getUrl({
    path: `public/json/${jsonName}.json`
  });
  const response = await axios.get<T>(jsonUrl.url.href);
  return response.data;
};

export const getJsonMasters = async <T>(
  jsonName: string
): Promise<T[] | undefined> => {
  const jsonUrl = await getUrl({
    path: `public/json/${jsonName}.json`
  });
  const response = await axios.get<T[]>(jsonUrl.url.href);
  return response.data;
};

export const getJsonAddressMasters = async <T>(
  jsonName: string,
  forAnonymous: boolean,
  sortFunc?: (a: T, b: T) => number
): Promise<T[] | undefined> => {
  const jsonUrl = await (forAnonymous
    ? invokeGetPresignedUrlForAnonymous(
        `public/json/${jsonName}.json`,
        "unauthenticated"
      )
    : getUrl({
        path: `public/json/${jsonName}.json`
      }).then((value) => value.url.href));

  const response = await axios.get<{
    key: T;
  }>(jsonUrl);

  const data: T[] = [];

  for (const [, value] of Object.entries(response.data)) {
    data.push(value);
  }

  if (sortFunc) {
    data.sort(sortFunc);
  }

  return data;
};
