import { Auth } from "components/Parts/Organisms/Auth";
import { ReportFilePage } from "components/Parts/Organisms/ReportFilePage";
import { ReportFileProps } from "components/Parts/Organisms/ReportFilePage/interfaces";
import React from "react";

export const ReportFile = React.memo((props: ReportFileProps) => (
  <Auth>
    <ReportFilePage {...props} />
  </Auth>
));
